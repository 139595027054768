import {
  EMAIL,
  FLOAT,
  FLOATING_POINT,
  INTEGER,
  NAME_TABLE_TAB_SPECIAL,
  NAME_TABLE_TAB_START,
  REGEX_INTERGER
} from '../@type/global';
import { convertValue } from './checkVariables';
import * as Yup from 'yup';
export const REGEX_CONFIG = {
  FLOAT: /^[0-9]+(\.[0-9]{0,2})?$/,
  FLOATING_POINT: /^[-+]?[0-9].?[0-9]+([eE][-+]?[0-9]+)?$/,
  INTEGER: /^[0-9]+$/,
  NAME_TABLE_TAB_START: /^[a-zA-Z_]+$/,
  NAME_TABLE_TAB_SPECIAL: /^[a-zA-Z0-9_]*$/,
  REGEX_INTERGER: /[^0-9.-]+/,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
};

export function isValidFormatWithRegex(regex: any, value: any) {
  if (value && regex) {
    const REGEXT = new RegExp(regex);
    return REGEXT.test(value);
  }
  return false;
}

export const typeRegex = (type: string) => {
  switch (type) {
    case FLOAT:
      return REGEX_CONFIG.FLOAT;
    case FLOATING_POINT:
      return REGEX_CONFIG.FLOATING_POINT;
    case INTEGER:
      return REGEX_CONFIG.INTEGER;
    case NAME_TABLE_TAB_START:
      return REGEX_CONFIG.NAME_TABLE_TAB_START;
    case NAME_TABLE_TAB_SPECIAL:
      return REGEX_CONFIG.NAME_TABLE_TAB_SPECIAL;
    case REGEX_INTERGER:
      return REGEX_CONFIG.REGEX_INTERGER;
    case EMAIL:
      return REGEX_CONFIG.EMAIL;
    default:
      return REGEX_CONFIG.FLOAT;
  }
};

export const handleRegexField = (e: any, field: string, type: string, setFieldValue: any) => {
  const value = e?.target?.value;
  // remove Number(value) because user may input "7."
  // remove value >= 0 because regex will check it
  if (regexFieldCheck(type, value)) {
    e.persist();
    setFieldValue(field, value);
  }
  if (value === '') {
    setFieldValue(field, '');
  }
  if (value === 0 || value === '0') {
    setFieldValue(field, 0);
  }
};

export const handleRegexFieldWithEvent = (e: any, type: string, setFieldValue: any) => {
  const value = e?.target?.value;
  if (regexFieldCheck(type, value)) {
    e.persist();
    e.target.value = convertValue(type, value);
    setFieldValue(e);
  }
};

export const createNumberValidation = ({
  isRequired = true,
  type = INTEGER,
  isGreaterThanZero = true
}: {
  isRequired?: boolean;
  type?: string;
  isGreaterThanZero?: boolean;
}) => {
  const validator = Yup.number()
    .transform((value, originalValue) =>
      String(originalValue).trim() === '' ? undefined : parseFloat(value)
    )
    .test('number-type', 'Required field', (value) => {
      if (isRequired) {
        return value !== undefined && value !== null;
      }
      return true;
    })
    .test('number-type', 'Invalid input', (value) => {
      if (value === null || value === undefined) {
        return false;
      }
      if (value < 0) {
        return false;
      }
      if (type === INTEGER) {
        const result = Number.isInteger(value);
        return result;
      }
      if (type === FLOAT) {
        // check is 2 max decimal
        const list = value.toString().split('.');
        if (list.length > 1) {
          if (list[1].length > 2) {
            return false;
          }
        }
      }
      return true;
    })
    .test('greater-than-zero', 'Value must be greater than 0', (value) => {
      if (isGreaterThanZero && typeof value === 'number') {
        return value > 0;
      }
      return true;
    });
  return validator;
};

export const createStrNumberValidation = ({
  isRequired = true,
  type = INTEGER,
  isGreaterThanZero = true
}: {
  isRequired?: boolean;
  type?: string;
  isGreaterThanZero?: boolean;
}) => {
  const validator = Yup.mixed()
    .test('is-required', 'This field is required', (value) => {
      if (isRequired) {
        return value !== undefined && value !== null && value !== '';
      }
      return true;
    })
    .test('is-number', 'Invalid number format', (value) => {
      if (value === undefined || value === null) {
        return !isRequired;
      }
      // return true is passed
      // return false is failed
      if (value === 'Infinite') {
        return true;
      }
      const num = Number(value);
      if (isNaN(num)) {
        return false; // If it's not a number and not empty string
      }

      // Check if the type is INTEGER and the number is not an integer
      if (type === INTEGER && !Number.isInteger(num)) {
        return false;
      }

      if (type === FLOAT && !Number.isInteger(num)) {
        const parts = num.toString().split('.');
        // only 2 decimal allowed
        // 1. is not allowed
        if (parts.length > 1 && parts[1].length === 0) {
          return false;
        }

        if (parts.length > 1 && parts[1].length > 2) {
          return false;
        }
      }

      return true;
    })
    .test('greater-than-zero', 'Value must be greater than 0', (value) => {
      if (!isRequired && (value === undefined || value === '')) {
        return true;
      }
      if (value === 'Infinite') {
        return true;
      }

      const num = Number(value);
      if (isGreaterThanZero) {
        return num > 0;
      }
      return num >= 0; // Allow zero if not strictly greater than zero
    });

  return validator;
};

export const createListValidation = ({
  isRequired = true,
  minLength = 1
}: {
  isRequired?: boolean;
  minLength?: number;
}) => {
  const validator = Yup.array()
    .test('is-required', 'This field is required', (value) => {
      if (isRequired) {
        return Array.isArray(value) && value.length > 0;
      }
      return true;
    })
    .test('min-length', `Array length must be at least ${minLength}`, (value) => {
      if (!isRequired && (value === undefined || value === null)) {
        return true;
      }
      return Array.isArray(value) && value.length >= minLength;
    });

  return validator;
};

export const createStringValidation = ({
  isRequired = true,
  maxLength = 255,
  minLength = 0
}: {
  isRequired?: boolean;
  maxLength?: number;
  minLength?: number;
}) => {
  let validator = Yup.string()
    .trim()
    .typeError('Please enter a valid string')
    .test('length-check', 'Required field', (value) => {
      if (value === undefined || value === null) {
        return !isRequired;
      }
      const length = value.length;
      return length >= minLength && length <= maxLength;
    });
  if (isRequired) {
    validator = validator.required('This field is required');
  }
  return validator;
};

export const regexFieldCheck = (type: string, value: string) => {
  if (Number(value) === 0) {
    return true;
  }
  return isValidFormatWithRegex(typeRegex(type), value);
};

export const handleRegexEvent = (e: any, type: string) => {
  const value = e?.target?.value;
  const regex = typeRegex(type);
  if (Number(value) && value >= 0 && isValidFormatWithRegex(regex, value)) {
    e.persist();
    return Number(value);
  }
  if (value === '') {
    return '';
  }
  if (value === 0 || value === '0') {
    return 0;
  }
  return '';
};

export const extractFileName = (url: string): string | null => {
  const decodedUrl = decodeURIComponent(url);
  const match = decodedUrl.match(/\/([^/?]+)(?=\?|$)/);
  return match ? match[1] : null;
};

export const extractIndexFromFileName = (fileName: string): number | null => {
  const match = fileName.match(/_(\d+)/);
  return match ? parseInt(match[1], 10) : null;
};
