import { Card, Grid2 } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
// routes
// @types
import { Promotion } from '../../../../@type/promotion';

import { PromotionType } from '@/__generated__/types';
import LoadingComponent from '@/components/LoadingComponent';
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { RootState } from '@/redux/store';
import { PATH_DASHBOARD } from '@/routes/paths';
import { getErrorMessage } from '@/utils/stringHelper';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { DeepKeyOf } from '../formik/type';
import { promoValidationSchema } from '../promoValidationSchema';
import {
  createPromoFunc,
  getInitialPromotionState,
  isEmptyReward,
  queryPromoFunc,
  updatePromoFunc
} from '../tools';
import { usePromoStepper } from './stepper/stepper';
import { StepRenderer } from './tabs/stepRenderer';
import { PromotionProps } from './type';

export const CreatePromotionForm = (props: PromotionProps) => {
  const { showSnackbar } = useSnackbarHelper();
  const user = useSelector((state: RootState) => state.auth.user);
  const params = useParams();
  const navigate = useNavigate();
  const { promoType = PromotionType.Percentage } = useParams<{ promoType: PromotionType }>();
  const { isLoyalty } = props;
  // get user form redux
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isEdit = params.name ? true : false;
  const [promotion] = useState<Promotion>(
    getInitialPromotionState(promoType, false, isLoyalty, user?.merchantId)
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: promotion,
    validationSchema: promoValidationSchema,
    onSubmit: async () => { }
  });
  const { setFieldTouched, setValues } = formik;

  const { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent } =
    usePromoStepper({
      isSubmitting,
      action: CreateOrUpdatePromotion(isEdit),
      promotion: formik.values
    });

  const setFieldTouchedName = useCallback(
    async (name: DeepKeyOf<Promotion>) => {
      if (!name) return;
      const formikErrors = await setFieldTouched(name, true, true);
      if (!formikErrors) return;
      let error = get(formikErrors, name);
      if (Array.isArray(error)) {
        error = error
          .map((err) => {
            if (typeof err === 'object' && err !== null) {
              return Object.values(err).join('; ');
            }
            return String(err);
          })
          .join('; ');
      }
      if (!error) return;
      throw new Error(JSON.stringify(error));
    },
    [setFieldTouched]
  );

  const fetchData = useCallback(async () => {
    if (!params.name) return;
    try {
      setIsLoading(true);
      const promotion = await queryPromoFunc(promoType, params.name);
      if (!promotion) return;
      setValues(promotion);
    } catch (error) {
      showSnackbar('Failed to fetch promotion', 'error');
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [params.name, promoType, setValues]);

  const isEmptyRewardCheckFailed = (promotion: Promotion) => {
    if (isEmptyReward(promotion)) {
      showSnackbar('Please fill in the reward details', 'error');
      return true;
    }
    return false;
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function createPromotion(isPublished: boolean) {
    if (isEmptyRewardCheckFailed(formik.values)) return;
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isSuccess = await createPromoFunc(formik.values, promoType, isPublished);
      if (isSuccess) {
        showSnackbar('Promotion created successfully', 'success');
        setIsSubmitting(false);
        navigate(PATH_DASHBOARD.promotion.list);
      }
    } catch (error) {
      showSnackbar('Failed to create promotion', 'error');
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
      setIsSubmitting(false);
    }
  }

  async function updatePromotion(isPublished: boolean) {
    if (isEmptyRewardCheckFailed(formik.values)) return;
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const isSuccess = await updatePromoFunc(formik.values, promoType, isPublished);
      if (isSuccess) {
        showSnackbar('Promotion update successfully', 'success');
        setIsSubmitting(false);
        navigate(PATH_DASHBOARD.promotion.list);
      }
    } catch (error) {
      showSnackbar('Failed to update promotion', 'error');
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
      setIsSubmitting(false);
    }
  }

  function CreateOrUpdatePromotion(isEdit: boolean) {
    if (isEdit) {
      return updatePromotion;
    } else {
      return createPromotion;
    }
  }

  const commonComponentProps = {
    formik,
    setCanMoveFunc,
    setFieldTouchedNameFunc: setFieldTouchedName,
  };


  if (isLoading && isEdit) {
    return <LoadingComponent />;
  }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid2 container spacing={3} justifyContent="center">
          <Grid2 size={{ xs: 12, sm: 10, md: 10 }}>
            <Card sx={{ p: 3 }}>
              <BreadcrumbsComponent />
              <StepRenderer currentStep={currentStep} commonComponentProps={commonComponentProps} formik={formik} />
              <MoveToStepComponent />
            </Card>
          </Grid2>
        </Grid2>
      </Form>
    </FormikProvider>
  );
};
