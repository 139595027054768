import { useCallback, useEffect, useMemo, useState } from 'react';
// material
import { Container, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// @types
// components
import { Promotion, PromotionFunctionType } from '../../../@type/promotion';
import { PromotionHistoryTable } from '../../../components/_dashboard/promotion/history/promotionHistoryTable';
import { getPromoFuncType } from '../../../components/_dashboard/promotion/tools';
import LoadingComponent from '../../../components/LoadingComponent';
import { useSnackbarHelper } from '../../../components/useSnackbarHelper';
import { useTranslation } from 'react-i18next';
import { fetchAllPromotionList } from '../../../redux/slices/promotion';
import { dispatch } from '../../../redux/store';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import { getErrorMessage } from '@/utils/stringHelper';

export default function PromotionHistory() {
  const { themeStretch } = useSettings();
  const { showSnackbar } = useSnackbarHelper();
  const { t } = useTranslation();
  const [filteredPromotions, setFilteredPromotions] = useState<Promotion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const queryAllPromotions = useCallback(async () => {
    try {
      setIsLoading(true);
      const promotionList = await dispatch(fetchAllPromotionList()).unwrap();
      const currentDate = new Date();
      const validPromotions = promotionList.filter((promotion) => {
        return promotion.effectiveDateTime.some((dateTime) => {
          // get the start date of the promotion
          const endDate = new Date(dateTime.endDate);
          // get the history of the promotion
          return currentDate > endDate;
        });
      });
      setFilteredPromotions(validPromotions);
    } catch (error) {
      showSnackbar('Get an error when fetching promotions', 'error');
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [showSnackbar]);

  useEffect(() => {
    queryAllPromotions();
  }, [queryAllPromotions]);

  const promotionList = useMemo(() => {
    return filteredPromotions.filter((promotion) => {
      const promoFuncType = getPromoFuncType(promotion);
      if (promoFuncType === PromotionFunctionType.Normal) {
        return promotion;
      }
      return false;
    });
  }, [filteredPromotions]);

  const loyaltyList = useMemo(() => {
    return filteredPromotions.filter((promotion) => {
      const promoFuncType = getPromoFuncType(promotion);
      if (promoFuncType === PromotionFunctionType.Loyalty) {
        return promotion;
      }
      return false;
    });
  }, [filteredPromotions]);

  const offerList = useMemo(() => {
    return filteredPromotions.filter((promotion) => {
      const promoFuncType = getPromoFuncType(promotion);
      if (promoFuncType === PromotionFunctionType.Offer) {
        return promotion;
      }
      return false;
    });
  }, [filteredPromotions]);

  return (
    <Page title={t('Promotion History')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('Promotion History')}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Promotion'), href: PATH_DASHBOARD.promotion.history },
            { name: t('History') }
          ]}
        />
        {isLoading && <LoadingComponent />}
        <Stack justifyContent="space-between" spacing={3} sx={{ mb: 5 }}>
          {promotionList.length > 0 && (
            <PromotionHistoryTable
              isLoading={isLoading}
              promotions={promotionList}
              tableTitle={'Promotions'}
            />
          )}
          {loyaltyList.length > 0 && (
            <PromotionHistoryTable
              isLoading={isLoading}
              promotions={loyaltyList}
              tableTitle={'Loyalty'}
            />
          )}
          {offerList.length > 0 && (
            <PromotionHistoryTable
              isLoading={isLoading}
              promotions={offerList}
              tableTitle={'Offer'}
            />
          )}
        </Stack>
      </Container>
    </Page>
  );
}
