import { ReactNode } from 'react';
// material
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// hooks
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

type ThemeLocalizationProps = {
  children: ReactNode;
};

const ThemeLocalization: React.FC<ThemeLocalizationProps> = (props: ThemeLocalizationProps) => {
  const { children } = props;
  const defaultTheme = useTheme();
  const { currentLang } = useLocales();

  const theme = createTheme(defaultTheme, currentLang.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ThemeLocalization;