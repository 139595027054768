import { Box, Card, Container, Stack, Tooltip, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
// components
import { RootState, useSelector } from '@/redux/store';
import { Icon } from '@iconify/react';
import Page from '../../components/Page';
import { AuthMethod } from '../../redux/slices/auth';
// layouts
// routes

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { authMethod } = useSelector((state: RootState) => state.auth);
  const getAuthMethodIcon = (authMethod: AuthMethod) => {
    switch (authMethod) {
      case AuthMethod.JWT:
        return 'logos:jwt-icon';
      case AuthMethod.Firebase:
        return 'logos:firebase';
      default:
        return 'logos:jwt-icon';
    }
  }
  return (
    <RootStyle title="Login">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Orderbuddy
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>

            <Tooltip title={authMethod}>
              <Box>
                <Icon icon={getAuthMethodIcon(authMethod)} width="32" height="32" />
              </Box>
            </Tooltip>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
