import { ObfaqQuestion } from '@/__generated__/types';
import { Icon } from '@iconify/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Container,
  Divider,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { lazy, Suspense, useMemo, useState } from 'react';
const LazyReactPlayer = lazy(() => import('react-player'));
interface IPreviewQuestionProps {
  FAQQuestion: ObfaqQuestion;
}
export const PreviewQuestion = (props: IPreviewQuestionProps) => {
  const { FAQQuestion } = props;
  const { question, answer, videoUrl, tags, id } = FAQQuestion;
  const [playing, setPlaying] = useState(false);
  const handlePlayVideoBtnOnClick = () => {
    setPlaying(true);
  };

  const tagChips = useMemo(() => {
    return tags?.map((tag, index) => <Chip key={id + tag + index} size="small" label={tag} />);
  }, [tags, id]);

  return (
    <>
      <Box sx={{ my: 1, justifyContent: 'center' }}>
        <Accordion>
          <AccordionSummary aria-controls="panel1-content">
            <Container sx={{ mb: 1 }}>
              <Typography variant="h6" gutterBottom>
                {question}
              </Typography>
              {tagChips}
            </Container>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" gutterBottom>
              {answer}
            </Typography>
            {videoUrl && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {!playing && (
                  <Icon
                    onClick={handlePlayVideoBtnOnClick}
                    icon="teenyicons:youtube-outline"
                    width="48"
                    height="48"
                  />
                )}
                {playing && (
                  <Suspense fallback={<div>Loading video...</div>}>
                    <LazyReactPlayer width={'100%'} url={videoUrl} controls playing={true} />
                  </Suspense>
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        <Divider sx={{ my: 1 }} variant="middle" />
      </Box>
    </>
  );
};
