import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateFaqQuestionDocument, CreateFaqQuestionMutation, CreateFaqQuestionMutationVariables, CreateFaqSectionDocument, CreateFaqSectionMutation, CreateFaqSectionMutationVariables, DeleteFaqQuestionDocument, DeleteFaqQuestionMutation, DeleteFaqQuestionMutationVariables, DeleteFaqSectionDocument, DeleteFaqSectionMutation, DeleteFaqSectionMutationVariables, FaqDocument, FaqQuery, FaqQueryVariables, ObApp, Obfaq, ObfaqQuestion, ObfaqSection, UpdateFaqQuestionDocument, UpdateFaqQuestionMutation, UpdateFaqQuestionMutationVariables, UpdateFaqSectionDocument, UpdateFaqSectionMutation, UpdateFaqSectionMutationVariables } from '@/__generated__/types';
import { getErrorMessage } from '@/utils/stringHelper';
import { client } from '../../index';

// ----------------------------------------------------------------------

type FAQState = {
  isLoading: boolean;
  error: boolean;
  FAQData: Record<ObApp, Obfaq>;
};

const initialState: FAQState = {
  isLoading: false,
  error: false,
  FAQData: {
    [ObApp.Booking]: { app: ObApp.Booking, sections: [] },
    [ObApp.MerchantApp]: { app: ObApp.MerchantApp, sections: [] },
    [ObApp.MerchantPortal]: { app: ObApp.MerchantPortal, sections: [] },
    [ObApp.MobileBuddy]: { app: ObApp.MobileBuddy, sections: [] },
    [ObApp.OrderbuddyApp]: { app: ObApp.OrderbuddyApp, sections: [] },
    [ObApp.OrderbuddyPortal]: { app: ObApp.OrderbuddyPortal, sections: [] },
    [ObApp.Pos]: { app: ObApp.Pos, sections: [] }
  }
};

const slice = createSlice({
  name: 'FAQ',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateFAQSections(state, action: PayloadAction<Obfaq>) {
      const { app, sections } = action.payload;
      if (!app || !sections) return;
      state.FAQData[app] = { ...state.FAQData[app], sections };
    },
    updateSection(
      state,
      action: PayloadAction<{
        app: ObApp;
        sectionId: string;
        title?: string;
        description?: string;
        questions?: ObfaqQuestion[];
        sort?: number;
      }>
    ) {
      const { app, sectionId, title, description, questions, sort } = action.payload;

      let obfaq = state.FAQData[app];

      if (!obfaq) {
        obfaq = {
          app,
          sections: [],
        };
        state.FAQData[app] = obfaq;
      }

      const sections = obfaq.sections ?? [];

      const sectionIndex = sections.findIndex((sec) => sec?.id === sectionId);
      const existingSection = sectionIndex > -1 ? sections[sectionIndex] : null;

      if (existingSection) {
        if (title) existingSection.title = title;
        if (description) existingSection.description = description;
        if (questions) existingSection.questions = questions;
        if (sort !== undefined) existingSection.sort = sort;
      } else {
        const newSection: ObfaqSection = {
          app: app,
          id: sectionId,
          title: title || '',
          description: description || '',
          questions: questions || [],
          sort: sort || 0,
        };
        sections.push(newSection);
      }
      obfaq.sections = sections;
      state.FAQData[app] = obfaq;
    },

    deleteSection(state, action: PayloadAction<{ app: ObApp; sectionId: string }>) {
      const { app, sectionId } = action.payload;
      const obfaq = state.FAQData[app];
      if (!obfaq || !obfaq.sections) return;
      obfaq.sections = obfaq.sections.filter((sec) => sec?.id !== sectionId);
      state.FAQData[app] = obfaq;
    },

    updateQuestion(
      state,
      action: PayloadAction<{
        app: ObApp;
        sectionId: string;
        questionId: string;
        question: ObfaqQuestion;
      }>
    ) {
      const { app, sectionId, questionId, question } = action.payload;
      const obfaq = state.FAQData[app];
      if (!obfaq || !obfaq.sections) return;
      const section = obfaq.sections.find((sec) => sec?.id === sectionId);
      if (!section || !section.questions) return;

      const questionIndex = section.questions.findIndex((q) => q?.id === questionId);
      if (questionIndex === -1) return;

      section.questions[questionIndex] = {
        ...section.questions[questionIndex],
        ...question,
      };
      section.questions.sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0));
      state.FAQData[app] = obfaq;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(queryAllOBFAQ.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(queryAllOBFAQ.fulfilled, (state, action) => {
        state.isLoading = false;

        const sortedFAQData: Record<ObApp, Obfaq> = {} as Record<ObApp, Obfaq>;

        action.payload.forEach((obfaq, app) => {
          if (obfaq && obfaq.sections) {
            const sortedSections = obfaq.sections
              .filter((section): section is ObfaqSection => !!section)
              .map((section) => {
                const sortedQuestions = [...(section.questions ?? [])].sort(
                  (a, b) => (a?.sort ?? 0) - (b?.sort ?? 0)
                );
                return {
                  ...section,
                  questions: sortedQuestions,
                };
              })
              .sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0));
            sortedFAQData[app] = {
              app,
              sections: sortedSections,
            };
          }
        });
        state.FAQData = sortedFAQData;
      })

      .addCase(queryAllOBFAQ.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        console.error(action.payload);
      })
      .addCase(queryFAQ.pending, (state) => {
        state.error = false;
      })
      .addCase(queryFAQ.fulfilled, (state, action) => {
        const { app, sections } = action.payload;
        if (!app || !sections) return;
        state.FAQData[app] = {
          app,
          sections: sections.filter((section): section is ObfaqSection => !!section),
        };
      })
      .addCase(queryFAQ.rejected, (state, action) => {
        state.error = true;
        console.error(action.payload);
      })
      // create question
      .addCase(createFAQQuestionMutation.pending, (state) => {
        state.error = false;
      })
      .addCase(createFAQQuestionMutation.rejected, (state, action) => {
        state.error = true;
        console.error(action.payload);
      })
      // delete question
      .addCase(deleteFAQQuestionMutation.pending, (state) => {
        state.error = false;
      })
      .addCase(deleteFAQQuestionMutation.fulfilled, (state, action) => {
        const { app, sectionId, questionId } = action.meta.arg;
        const isDeleted = action.payload;

        if (!isDeleted) return;

        const obfaq = state.FAQData[app];
        if (!obfaq || !obfaq.sections) return;
        const section = obfaq.sections.find((sec) => sec?.id === sectionId);
        if (!section || !section.questions) return;
        section.questions = section.questions.filter((q) => q?.id !== questionId);
        state.FAQData[app] = obfaq;
      })
      .addCase(deleteFAQQuestionMutation.rejected, (state, action) => {
        state.error = true;
        console.error(action.payload);
      })
  }
});

export const { updateSection, deleteSection, updateQuestion, updateFAQSections } = slice.actions;

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

// faq(
//   app: OBApp!
//   ): OBFAQ
export const queryFAQ = createAsyncThunk(
  'FAQ/queryFAQ',
  async (input: FaqQueryVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.query<FaqQuery>({
        query: FaqDocument,
        variables: input,
        fetchPolicy: 'network-only',
      });

      return data?.faq ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// queryOBFAQ
export const queryAllOBFAQ = createAsyncThunk<
  Map<ObApp, Obfaq>,
  void,
  { rejectValue: string }
>('FAQ/queryAllOBFAQ', async (_, { rejectWithValue, dispatch }) => {
  try {
    const appValues = Object.values(ObApp);
    const results = await Promise.all(
      appValues.map((app) => dispatch(queryFAQ({ app })).unwrap())
    );

    // 创建一个新的 Map<ObApp, Obfaq>
    const FAQData = new Map<ObApp, Obfaq>();

    results.forEach((result, index) => {
      const app = appValues[index];
      if (result.sections) {
        FAQData.set(app, {
          app,
          sections: result.sections.filter((section): section is ObfaqSection => !!section),
        });
      }
    });

    return FAQData;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return rejectWithValue(errorMessage);
  }
});


// createFAQSection
// done
export const createFAQSectionMutation = createAsyncThunk<
  boolean,
  CreateFaqSectionMutationVariables,
  { rejectValue: string }
>('FAQ/createFAQSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data, errors } = await client.mutate<CreateFaqSectionMutation>({
      mutation: CreateFaqSectionDocument,
      variables: input,
    });

    if (errors) {
      return rejectWithValue(JSON.stringify(errors));
    }

    return data?.createFAQSection ?? rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

// updateFAQSection
export const updateFAQSectionMutation = createAsyncThunk<
  boolean,
  UpdateFaqSectionMutationVariables,
  { rejectValue: string }
>('FAQ/updateFAQSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data, errors } = await client.mutate<UpdateFaqSectionMutation>({
      mutation: UpdateFaqSectionDocument,
      variables: input,
    });

    if (errors) {
      return rejectWithValue(JSON.stringify(errors));
    }

    return data?.updateFAQSection ?? rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

// deleteFAQSection
// done
export const deleteFAQSectionMutation = createAsyncThunk<
  boolean,
  DeleteFaqSectionMutationVariables,
  { rejectValue: string }
>('FAQ/deleteFAQSectionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data, errors } = await client.mutate<DeleteFaqSectionMutation>({
      mutation: DeleteFaqSectionDocument,
      variables: input,
    });

    if (errors) {
      return rejectWithValue(JSON.stringify(errors));
    }
    return data?.deleteFAQSection ?? rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

// createFAQQuestion

export const createFAQQuestionMutation = createAsyncThunk<
  boolean,
  CreateFaqQuestionMutationVariables,
  { rejectValue: string }
>('FAQ/createFAQQuestionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data, errors } = await client.mutate<CreateFaqQuestionMutation>({
      mutation: CreateFaqQuestionDocument,
      variables: input,
    });

    if (errors) {
      return rejectWithValue(JSON.stringify(errors));
    }

    return data?.createFAQQuestion ?? rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

// updateFAQQuestion
export const updateFAQQuestionMutation = createAsyncThunk<
  boolean,
  UpdateFaqQuestionMutationVariables,
  { rejectValue: string }
>('FAQ/updateFAQQuestionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data, errors } = await client.mutate<UpdateFaqQuestionMutation>({
      mutation: UpdateFaqQuestionDocument,
      variables: input,
    });

    if (errors) {
      return rejectWithValue(JSON.stringify(errors));
    }

    return data?.updateFAQQuestion ?? rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

// deleteFAQQuestion
export const deleteFAQQuestionMutation = createAsyncThunk<
  boolean,
  DeleteFaqQuestionMutationVariables,
  { rejectValue: string }
>('FAQ/deleteFAQQuestionMutation', async (input, { rejectWithValue }) => {
  try {
    const { data, errors } = await client.mutate<DeleteFaqQuestionMutation>({
      mutation: DeleteFaqQuestionDocument,
      variables: input,
    });

    if (errors) {
      return rejectWithValue(JSON.stringify(errors));
    }

    return data?.deleteFAQQuestion ?? rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});