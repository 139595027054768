// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
// redux
import { persistor, store } from './redux/store';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
// components
import LoadingScreen from './components/LoadingScreen';

import { setContext } from '@apollo/client/link/context';
// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from './App';
// ----------------------------------------------------------------------
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import './utils/lenguage/config';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { GiftCardAccount } from './__generated__/types';

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_BASE_URL,
  credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      clientID: import.meta.env.VITE_CLIENT_ID
    }
  };
});

// export to redux
const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        giftCardAccount: {
          merge(existing = {}, incoming): GiftCardAccount {
            return {
              ...existing,
              ...incoming,
              activeGiftCards: [
                ...(existing.activeGiftCards || []),
                ...(incoming.activeGiftCards || [])
              ],
              redeemedGiftCards: [
                ...(existing.redeemedGiftCards || []),
                ...(incoming.redeemedGiftCards || [])
              ],
              voidedGiftCards: [
                ...(existing.voidedGiftCards || []),
                ...(incoming.voidedGiftCards || [])
              ]
            };
          }
        }
      }
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(ApolloLink.from([httpLink])),
  cache: cache
});

const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <ApolloProvider client={client}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                  </ApolloProvider>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}
