import { Grid2 } from '@mui/material';
import React from 'react';

export interface tabContent {
  node: React.ReactElement | JSX.Element | tabContent;
  mdSize?: number;
  sxSize?: number;
  isHidden?: boolean;
}

export const renderContent = (contents: tabContent[]): React.ReactNode => {
  return contents.map((content, index) => {
    if (content.isHidden) {
      return null;
    }
    const node = (content.node as tabContent).node
      ? renderContent([content.node as tabContent])
      : (content.node as React.ReactNode);

    return (
      <Grid2 key={index} size={{ xs: content.sxSize || 12, md: content.mdSize || 6 }}>
        {node}
      </Grid2>
    );
  });
};
