import { getErrorMessage } from '@/utils/stringHelper';
import { LoadingButton } from '@mui/lab';
import { TableCell, TableRow } from '@mui/material';
import { Promotion } from '@/@type/promotion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fDateTimeSecond } from '@/utils/formatTime';

interface PromotionHistoryTableRowProps {
  data: Promotion;
  duplicatePromotion: (promotion: Promotion) => Promise<void>;
}

export const PromotionHistoryTableRow: React.FC<PromotionHistoryTableRowProps> = (
  props: PromotionHistoryTableRowProps
) => {
  const { t } = useTranslation();
  const { data, duplicatePromotion } = props;
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const { name, type, description, createdAt } = data;

  const handleDuplicatePromotion = async (promotion: Promotion) => {
    if (isDuplicateLoading) return;
    setIsDuplicateLoading(true);
    try {
      await duplicatePromotion(promotion);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
    } finally {
      setIsDuplicateLoading(false);
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{description}</TableCell>
      <TableCell align="left">{createdAt && fDateTimeSecond(createdAt / 1000)}</TableCell>
      <TableCell align="left">{type}</TableCell>
      <TableCell align="left">
        <LoadingButton
          size="small"
          variant="outlined"
          loading={isDuplicateLoading}
          onClick={() => handleDuplicatePromotion(data)}
        >
          {t('Duplicate')}
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};
