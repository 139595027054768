import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormikProps } from 'formik';
import { Promotion } from '@/@type/promotion';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';

interface percentageSelectorProp {
  formik: FormikProps<Promotion>;
  isOffer: boolean;
  label?: string;
}

export const PercentageSelector: React.FC<percentageSelectorProp> = (
  props: percentageSelectorProp
) => {
  const { formik, isOffer, label } = props;
  const { t } = useTranslation();
  const handleIsPercentageOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    if (value) {
      formik.setFieldValue('discountAmount', undefined);
    } else {
      formik.setFieldValue('upToAmount', 0);
      formik.setFieldValue('percentage', undefined);
    }
    formik.setFieldValue('isPercentage', value);
  };
  const radioStyle = isOffer ? { color: '#DCC179' } : {};
  return (
    <>
      <FormControl>
        {label && <Label>{t(label)}</Label>}
        <RadioGroup
          row
          name="type"
          value={formik.values.isPercentage}
          onChange={handleIsPercentageOnChange}
        >
          <FormControlLabel
            value={true}
            checked={formik.values.isPercentage}
            control={<Radio style={radioStyle} />}
            label="Percentage %"
          />
          <FormControlLabel
            value={false}
            checked={!formik.values.isPercentage}
            control={<Radio style={radioStyle} />}
            label="Amount $"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};
