import { ColorSchema } from '@/@type/theme';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import { Icon, IconifyIcon } from '@iconify/react';
import { Box } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';

type SnackbarIconProps = {
  icon: IconifyIcon;
  color: ColorSchema;
};

function SnackbarIcon(props: SnackbarIconProps): JSX.Element {
  const { icon, color } = props;
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16)
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

type NotistackProviderProps = {
  children: ReactNode;
};

const NotistackProvider: React.FC<NotistackProviderProps> = (props:NotistackProviderProps) => {
  const { children } = props;
  const theme = useTheme();
  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    width: '100%',
    padding: theme.spacing(1.5),
    margin: theme.spacing(0.25, 0),
    boxShadow: theme.customShadows.z8,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '&.notistack-MuiContent-success, &.notistack-MuiContent-error, &.notistack-MuiContent-warning, &.notistack-MuiContent-info':
      {
        width: '100%',
        padding: theme.spacing(1.5),
        margin: theme.spacing(0.25, 0),
        boxShadow: theme.customShadows.z8,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper
      },
    '&.notistack-MuiContent-message': {
      padding: '0 !important',
      fontWeight: theme.typography.fontWeightMedium
    },
    '&.notistack-MuiContent-action': {
      marginRight: 0,
      color: theme.palette.action.active,
      '& svg': {
        width: 20,
        height: 20
      }
    }
  }));

  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      // preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      iconVariant={{
        success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
        error: <SnackbarIcon icon={infoFill} color="error" />,
        warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
        info: <SnackbarIcon icon={alertCircleFill} color="info" />
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;