// routes
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import GoogleAnalytics from './components/GoogleAnalytics';
import { ProgressBarStyle } from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
// components
import ThemeLocalization from './components/ThemeLocalization';
import ThemePrimaryColor from './components/ThemePrimaryColor';
// hooks
import Router from './routes';
// theme
import PWABadge from './PWABadge';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

// ----------------------------------------------------------------------

const App: React.FC = () => {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <GoogleAnalytics />
            <Router />
            <PWABadge />
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

export default App;