import { Container } from '@mui/material';
import { useEffect } from 'react';

import { Box } from '@mui/system';
// components
// hooks
// routes

import { useLazyQuery, useMutation } from '@apollo/client';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { MIconButton } from '@/components/@material-extend';
import HeaderBreadcrumbs from '@/components/HeaderBreadcrumbs';
import LoadingComponent from '@/components/LoadingComponent';
import Page from '@/components/Page';
import useSettings from '@/hooks/useSettings';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { PATH_DASHBOARD } from '@/routes/paths';
import { convertCamelCase } from '@/utils/convertCamelCase';
import {
  CREATE_OR_UPDATE_TOP_RESTAURANT_TYPES,
  TOP_RESTAURANT_TYPES
} from '@/_apis_/queries/topRestaurantTypes';
import PopularRestaurantCategoriesForm from './components/PopularRestaurantCategoriesForm';
import PopularRestaurantcategoriesItem from './components/PopularRestaurantcategoriesItem';

const PopularRestaurantCategories = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [allPopular, setAllPopular] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [getAllPopularRestaurantCategories] = useLazyQuery(TOP_RESTAURANT_TYPES, {
    onCompleted(response) {
      setAllPopular(response?.topRestaurantTypes);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
    fetchPolicy: 'network-only'
  });

  const [createTopRestaurantTypes] = useMutation(CREATE_OR_UPDATE_TOP_RESTAURANT_TYPES, {
    onCompleted() {
      setLoading(false);
      enqueueSnackbar(t('Update Success'), {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    },
    fetchPolicy: 'network-only',
    onError() {
      setLoading(false);
      enqueueSnackbar(t('Update Fail'), {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });

  useEffect(() => {
    getAllPopularRestaurantCategories();
  }, [getAllPopularRestaurantCategories, loading]);

  return (
    <Page title={t('Popular Restaurant Categoris| Minimal-UI')}>
      <DndProvider backend={HTML5Backend}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={t('Popular Restaurant Categories')}
            links={[
              { name: t('Dash Board'), href: PATH_DASHBOARD.root },
              {
                name: t('Popular Restaurant Categories'),
                href: PATH_DASHBOARD.popularRestaurantCategories.list
              },
              { name: t('List') }
            ]}
          />

          <PopularRestaurantCategoriesForm
            createTopRestaurantTypes={createTopRestaurantTypes}
            setLoading={setLoading}
            allPopular={allPopular}
          />

          <Box
            display="flex"
            justifyContent="space-between"
            width="60%"
            style={{
              padding: '10px',
              border: '1px dashed black',
              borderRadius: '10px',
              marginTop: '20px'
            }}
          >
            <Box
              style={{
                width: '100%',
                minHeight: '600px',
                maxHeight: '600px',
                overflowY: 'scroll'
              }}
            >
              {!loading ? (
                allPopular?.map((popular: any, index: number) => {
                  return (
                    <PopularRestaurantcategoriesItem
                      key={index}
                      popular={convertCamelCase(popular)}
                      index={index}
                    />
                  );
                })
              ) : (
                <LoadingComponent />
              )}
            </Box>
          </Box>
        </Container>
      </DndProvider>
    </Page>
  );
};

export default PopularRestaurantCategories;
