import LoadingScreen from '@/components/LoadingScreen';
import { getUserDetail, logout } from '@/redux/slices/auth';
import { dispatch, RootState, useSelector } from '@/redux/store';
import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// pages
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { getErrorMessage } from '@/utils/stringHelper';
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbarHelper();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      queryUserDetail();
    }
  }, [isAuthenticated]);

  const queryUserDetail = async () => {
    try {
      setIsLoading(true);
      const user = await dispatch(getUserDetail()).unwrap();
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        setIsLoading(false);
        return;
      }
    } catch (error) {
      showSnackbar('Query user detail failed', 'error');
      console.error("error: ", getErrorMessage(error));
      dispatch(logout());
      setIsLoading(false);
      localStorage.clear();
      window.location.reload();
    }
  };

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (!user?.id) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return isLoading ? <LoadingScreen /> : <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
