import { Container, Divider, Typography } from '@mui/material';

import { ObfaqSection } from '@/__generated__/types';
import { useState } from 'react';
import { PreviewSection } from './previewSection';
import { FAQSearchInput } from './searchInput';

interface IPreviewSectionsProps {
  sections: ObfaqSection[];
}
export const PreviewSectionsBox = (props: IPreviewSectionsProps) => {
  const { sections } = props;
  const [mySections, setMySections] = useState<ObfaqSection[]>(sections);
  const handleKeywordChange = (keyword: string) => {
    if (keyword === '') {
      setMySections(sections);
      return;
    }

    const newSections = sections.flatMap((section) => {
      const filteredQuestions = (section.questions ?? []).filter(
        (question) =>
          question?.question.toLowerCase().includes(keyword.toLowerCase()) ||
          question?.answer.toLowerCase().includes(keyword.toLowerCase())
      );

      return filteredQuestions.length > 0 ? [{ ...section, questions: filteredQuestions }] : [];
    });

    setMySections(newSections);
  };

  return (
    <>
      <Container
        sx={{
          mt: 10,
          width: { xs: '100vw', sm: '70vw' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant="h3" gutterBottom>
          {'Frequently Asked Questions'}
        </Typography>
        <FAQSearchInput handleKeywordChange={handleKeywordChange} />
        {mySections.map((section, index) => (
          <PreviewSection key={index} section={section} />
        ))}
        <Divider variant="middle" />
      </Container>
    </>
  );
};
