export const formatNumericValue = (value: string | number, isPercentage: boolean): string => {
  if (isPercentage) {
    return `${value}%`;
  } else {
    return `${value}$`;
  }
};

export function getErrorMessage(error: unknown): string {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'An unknown error occurred';
}

