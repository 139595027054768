import { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
// material
import { Box, BoxProps } from '@mui/material';
// utils

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}
const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => {
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});
Page.displayName = 'Page';
export default Page;
