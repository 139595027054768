import { PromoCustomerSegment } from '@/components/_dashboard/promotion/components/promoCustomerSegment';
import { CommonInput } from '@/components/_dashboard/promotion/formik/commonInput';
import {
  getBaseInfoValidationList,
  getPromoDescriptionPlaceholder,
  getPromoFuncType,
  getPromoNamePlaceholder
} from '@/components/_dashboard/promotion/tools';
import { getErrorMessage } from '@/utils/stringHelper';
import { Grid2 } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { IPromoProps } from '../../type';
import { renderContent, tabContent } from '../tabContent';

export const VoucherBaseInfo: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  const { isLoyalty } = values;
  const canMove = useCallback(async () => {
    // name is required
    // numberOfDeals is required and greater than 0
    let message = '';
    let canMove = true;
    try {
      const promoFuncType = getPromoFuncType(values);
      const list = getBaseInfoValidationList(promoFuncType);
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
    } catch (e) {
      message = getErrorMessage(e);
      canMove = false;
    }
    return {
      message: message,
      canMove: canMove
    };
  }, [setFieldTouchedNameFunc, values]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      sxSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'name'}
          label="Name"
          placeholder={getPromoNamePlaceholder(values)}
        />
      )
    },
    {
      mdSize: 12,
      sxSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'description'}
          label="Description"
          placeholder={getPromoDescriptionPlaceholder(values)}
          multiline={true}
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'numberOfDeals'}
          type="number"
          label="How many deals would you like to publish?"
          placeholder="Number of Deals"
        />
      ),
      isHidden: isLoyalty
    },
    {
      node: <></>
    },
    {
      node: <PromoCustomerSegment formik={formik} />,
      isHidden: isLoyalty
    }
  ];

  return (
    <Grid2 sx={{ width: '100%' }} container spacing={1}>
      {renderContent(commonContents)}
    </Grid2>
  );
};
