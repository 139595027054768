import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import { FormikProps } from 'formik';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../styles';
import { DeepKeyOf, FieldType } from './type';

interface CommonSelectProps<T, K extends DeepKeyOf<T>> {
  formik: FormikProps<T>;
  fieldPath: K;
  options: Record<string, FieldType<T, K>>;
  label?: string;
  tooltipText?: string;
  sx?: CSSProperties;
}

export const CommonSelect = <T, K extends DeepKeyOf<T>>(props: CommonSelectProps<T, K>) => {
  const { formik, fieldPath = '', options, sx = {}, label, tooltipText } = props;
  const { getFieldProps } = formik;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const fieldValue = getFieldProps(fieldPath).value || '';
  const handleOnChange = (event: SelectChangeEvent) => {
    formik.setFieldValue(fieldPath, event.target.value);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        {label && (
          <Label>
            {t(label)}
            {tooltipText && (
              <Tooltip title={t(tooltipText)}>
                <IconButton>
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Label>
        )}
        <Select value={fieldValue} onChange={handleOnChange} label={label}>
          {Object.entries(options).map(([key, value]) => (
            <MenuItem value={String(value)} key={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
