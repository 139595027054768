
import { ObfaqQuestion } from '@/__generated__/types';
import { PreviewQuestion } from './previewQuestion';

interface IPreviewQuestionsProps {
  questions: ObfaqQuestion[];
}
export const PreviewQuestions = (props: IPreviewQuestionsProps) => {
  const { questions } = props;
  return (
    <>
      {questions.map((question) => (
        <PreviewQuestion key={question.id} FAQQuestion={question} />
      ))}
    </>
  );
};
