import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoyaltyMember } from '../../@type/loyaltyMember';
import { Promotion } from '../../@type/promotion';
import { GET_LOYALTY_MEMBERS, GET_PROMOTIONS_ON_LOYALTY_MEMBERS } from '../../_apis_/queries/loyalty';
import { removeTypenameKey } from '../../components/_dashboard/promotion/tools';
import { client } from '../../index';
import { cloneDeep } from 'lodash';
import { getErrorMessage } from '@/utils/stringHelper';

type LoyaltyState = {
  loyaltyMembers: LoyaltyMember[];
  promotions: Promotion[];
  isLoadingPromotions: boolean;
};

const initialState: LoyaltyState = {
  loyaltyMembers: [],
  promotions: [],
  isLoadingPromotions: false
};

const slice = createSlice({
  name: 'loyalty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotions.pending, (state) => {
        state.isLoadingPromotions = true;
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        if (action.payload) {
          state.promotions = action.payload;
        }
        state.isLoadingPromotions = false;
      })
      .addCase(fetchPromotions.rejected, (state) => {
        state.isLoadingPromotions = false;
      })
      .addCase(fetchLoyaltyMembers.fulfilled, (state, action) => {
        if (action.payload) {
          state.loyaltyMembers = action.payload;
        }
      });
  }
});

export const fetchLoyaltyMembers = createAsyncThunk(
  'loyalty/fetchLoyaltyMembers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ loyaltyMembers: LoyaltyMember[] }>({
        query: GET_LOYALTY_MEMBERS,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      if (!response.data.loyaltyMembers) return [];
      // response.data.loyaltyMembers is read-only object
      const copy = cloneDeep(response.data.loyaltyMembers);
      removeTypenameKey(copy);
      return copy;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPromotions = createAsyncThunk(
  'loyalty/fetchPromotions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getPromotions: Promotion[] }>({
        query: GET_PROMOTIONS_ON_LOYALTY_MEMBERS,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      // response.data.getPromotions is read-only object
      const copy = cloneDeep(response.data.getPromotions);
      removeTypenameKey(copy);
      return copy;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);

// Reducer
export default slice.reducer;
