import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { rootReducer } from './rootReducer';

// ----------------------------------------------------------------------


const store = configureStore({
  reducer: rootReducer,
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useSelector = useReduxSelector;
const useDispatch: () => AppDispatch = () => useReduxDispatch<AppDispatch>();

export { dispatch, persistor, store, useDispatch, useSelector };

