import { FormikProps } from 'formik';
import { Promotion } from '@/@type/promotion';
import { CommonInput } from '../formik/commonInput';

interface promoDiscountAmountProps {
  formik: FormikProps<Promotion>;
}
export const PromoDiscountAmount: React.FC<promoDiscountAmountProps> = (
  props: promoDiscountAmountProps
) => {
  const { formik } = props;
  const { isPercentage } = formik.values;

  const handleDiscountOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isPercentage = formik.values.isPercentage;
    if (isPercentage === true) {
      formik.setFieldValue('percentage', value);
      formik.setFieldValue('discountAmount', 0);
    }
    if (isPercentage === false) {
      formik.setFieldValue('discountAmount', value);
      formik.setFieldValue('percentage', 0);
    }
  };
  return (
    <>
      <CommonInput
        formik={formik}
        fieldPath={isPercentage ? 'percentage' : 'discountAmount'}
        type="text"
        handleOnChange={handleDiscountOnChange}
        label="Discount Amount"
        placeholder="Discount Amount"
        endAdornment={isPercentage ? '%' : 'AUD'}
      />
    </>
  );
};
