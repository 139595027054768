import { Box } from '@mui/material';
import PaymentErrorIcon from '@/assets/png/paymentError.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import {
  BoxWarrperButton,
  ButtonAction,
  CardStyle,
  ContainerStyle,
  ImageBox,
  RootStyle,
  TypographyCenter
} from './style';
const PaymentError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubscriptions = () => {
    navigate(PATH_DASHBOARD.merchant.subscriptions);
  };

  const onContinueSubscribe = () => {
    navigate(PATH_DASHBOARD.merchant.productList);
  };

  const onPaymentAgain = () => {};

  return (
    <RootStyle title="Payment Error">
      <ContainerStyle>
        <CardStyle>
          <Box>
            <ImageBox src={PaymentErrorIcon}></ImageBox>
          </Box>
          <Box style={{ padding: '20px' }}>
            <TypographyCenter variant="h3">{t('Your Payment is Error!')}</TypographyCenter>
            <TypographyCenter variant="h4">
              {t('An error occurred during payment')}
            </TypographyCenter>
            <TypographyCenter variant="h5">{t('Please try again!')}</TypographyCenter>
          </Box>
          <BoxWarrperButton>
            <ButtonAction onClick={onSubscriptions} variant="contained">
              {t('ALL SUBSCRIPTIONS')}
            </ButtonAction>
            <ButtonAction onClick={onContinueSubscribe} variant="contained">
              {t('ALL PRODUCT')}
            </ButtonAction>
            <ButtonAction onClick={onPaymentAgain} variant="contained">
              {t('PAYMENT AGAIN')}
            </ButtonAction>
          </BoxWarrperButton>
        </CardStyle>
      </ContainerStyle>
    </RootStyle>
  );
};

export default PaymentError;
