import { DateTime } from 'luxon';

export const convertTime = (timeValue: any, type: string) => {
  const time = DateTime.fromJSDate(timeValue);

  if (type === 'time') {
    const hours = time.hour * 60;
    const minutes = time.minute;
    return hours + minutes;
  }

  return timeValue.getTime();
};

export const parseTime = (time: number) => {
  const formatTime = DateTime.utc().startOf('day').plus({ minutes: time });
  return formatTime;
};
