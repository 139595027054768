import { ObfaqQuestion, ObfaqSection } from '@/__generated__/types';
import { Box, Divider, Typography } from '@mui/material';
import { PreviewQuestions } from '../question/previewQuestions';

interface IPreviewSectionProps {
  section: ObfaqSection;
}
export const PreviewSection = (props: IPreviewSectionProps) => {
  const { section } = props;
  const { title, description, questions } = section;
  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h3" gutterBottom align="center">
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        {description}
      </Typography>
      <Divider variant="middle" />
      <PreviewQuestions
        questions={(questions ?? []).filter((q): q is ObfaqQuestion => q !== null)}
      />
    </Box>
  );
};
