import {
  AppContentLocation,
  AppContentType,
  AppSection,
  AppSectionInput,
  AppSectionItem,
  AppViewType,
  ContentStatus,
  Merchant
} from '@/__generated__/types';

export const initAppSection: AppSection = {
  id: '',
  contentType: AppContentType.New,
  viewType: AppViewType.VerticalList,
  sort: 0,
  location: AppContentLocation.Home,
  merchants: [],
  merchantIds: [],
  items: [],
  image: '',
  title: '',
  description: '',
  status: ContentStatus.Draft
};

export const convertAppSectionInput = (appSection: AppSection): AppSectionInput => {
  const merchantIds =
    appSection.merchants
      ?.filter((merchant): merchant is Merchant => merchant !== null)
      .map((merchant) => merchant.merchantId) || [];

  const items =
    appSection.items
      ?.filter((item): item is AppSectionItem => item !== null)
      .map((item) => item.id) || [];

  const input: AppSectionInput = {
    contentType: appSection.contentType,
    viewType: appSection.viewType,
    sort: appSection.sort,
    location: appSection.location,
    merchantIds: merchantIds,
    items: items,
    title: appSection.title,
    description: appSection.description,
    images: appSection.image,
    status: appSection.status || ContentStatus.Draft
  };

  if (appSection.radiumInKm) {
    input.radiumInKm = Number(appSection.radiumInKm);
  }

  if (appSection.coordinate) {
    const { latitude, longitude } = appSection.coordinate;
    input.coordinate = {
      latitude: Number(latitude),
      longitude: Number(longitude)
    };
  }

  return input;
};
