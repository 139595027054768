import { LoginMutationVariables, LogoutMutationVariables, ResetPasswordMutationVariables, User } from '@/__generated__/types';
import { LOGIN, LOGOUT, RESET_PASSWORD } from '@/_apis_/queries/auth';
import { GET_USER_DETAIL } from '@/_apis_/queries/user';
import { client } from '@/index';
import { getErrorMessage } from '@/utils/stringHelper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export type AuthMerchant = null | Record<string, any>;

export enum AuthMethod {
  JWT,
  Firebase,
  SSO,
}


interface IAuth {
  authMethod: AuthMethod;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  error: boolean;
  isInitialized: boolean;
  user: User | null;
  merchant: AuthMerchant;
  isLoadingGetMerchantSuccess: boolean;
}

const initialState: IAuth = {
  authMethod: AuthMethod.JWT,
  isAuthenticating: false,
  error: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  merchant: null,
  isLoadingGetMerchantSuccess: true
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: IAuth) {
      state.isAuthenticating = true;
    },
    // HAS ERROR
    hasError(state: IAuth, action: any) {
      state.isAuthenticating = false;
      state.error = action.payload;
    },
    loginSuccess(state: IAuth) {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.isInitialized = true;
    },
    logout(state: IAuth) {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.isInitialized = false;
      state.user = null;
      state.isLoadingGetMerchantSuccess = true;
      state.merchant = null;
    },
    getUserLogin(state: IAuth, action: any) {
      state.isAuthenticating = false;
      state.user = action.payload;
    },
    getAuthMerchantSuccess(state: IAuth, action: any) {
      state.isAuthenticating = false;
      state.merchant = action.payload;
      state.isLoadingGetMerchantSuccess = false;
    },
    updateOpeningHoursSuccess(state: IAuth) {
      state.isLoadingGetMerchantSuccess = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginMutation.pending, (state) => {
        state.isAuthenticating = true;
        state.error = false;
      })
      .addCase(loginMutation.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticating = false;
        state.isAuthenticated = true;
        state.error = false;
      })
      .addCase(loginMutation.rejected, (state) => {
        state.isAuthenticating = false;
        state.error = true;
      })
      .addCase(getUserDetail.pending, (state) => {
        state.isAuthenticating = true;
        state.error = false;
      })
      .addCase(getUserDetail.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticating = false;
        state.error = false;
      })
      .addCase(getUserDetail.rejected, (state) => {
        state.isAuthenticating = false;
        state.error = true;
      })
      .addCase(logoutMutation.pending, (state) => {
        state.isAuthenticating = true;
        state.error = false;
      })
      .addCase(logoutMutation.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticating = false;
        state.error = false;
        state.isAuthenticated = false;
      })
      .addCase(logoutMutation.rejected, (state) => {
        state.isAuthenticating = false;
        state.error = true;
      })
      .addCase(resetPasswordMutation.pending, (state) => {
        state.isAuthenticating = true;
        state.error = false;
      })
      .addCase(resetPasswordMutation.fulfilled, (state) => {
        state.isAuthenticating = false;
        state.error = false;
      })
      .addCase(resetPasswordMutation.rejected, (state) => {
        state.isAuthenticating = false;
        state.error = true;
      })
  }
});

export const loginMutation = createAsyncThunk<
  User,
  LoginMutationVariables,
  { rejectValue: string }
>('auth/loginMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ login: User }>({
      mutation: LOGIN,
      variables: input
    });
    if (response.data) {
      return response.data.login;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return rejectWithValue(errorMessage);
  }
}
);


// export const GET_USER_DETAIL = gql`
//   query getUserDetail {

export const getUserDetail = createAsyncThunk(
  'auth/getUserDetail',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getUserDetail: User }>({
        query: GET_USER_DETAIL,
        fetchPolicy: 'network-only'
      });
      if (response.data) {
        return response.data.getUserDetail;
      }
      return rejectWithValue('No data returned');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);


// export const LOGOUT = gql`
//   mutation Logout {
//     logout
//   }
// `;
export const logoutMutation = createAsyncThunk<
  boolean,
  LogoutMutationVariables,
  { rejectValue: string }
>('auth/logoutMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ logout: boolean }>({
      mutation: LOGOUT,
      variables: input
    });
    if (response.data) {
      return response.data.logout;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return rejectWithValue(errorMessage);
  }
}
);

// export const RESET_PASSWORD = gql`
//   mutation resetPassword($email: String!) {
//     resetPassword(email: $email)
//   }
// `;
export const resetPasswordMutation = createAsyncThunk<
  boolean,
  ResetPasswordMutationVariables,
  { rejectValue: string }
>('auth/resetPasswordMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ resetPassword: boolean }>({
      mutation: RESET_PASSWORD,
      variables: input
    });
    if (response.data) {
      return response.data.resetPassword;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    return rejectWithValue(errorMessage);
  }
}
);

export const {
  loginSuccess,
  logout,
  getUserLogin,
  getAuthMerchantSuccess,
  updateOpeningHoursSuccess
} = authSlice.actions;
export default authSlice.reducer;
