import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { getErrorMessage } from '@/utils/stringHelper';
import { Booking } from '../../@type/booking';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type BookingState = {
  isLoading: boolean;
  error: boolean;
  bookingList: Booking[];
};

const initialState: BookingState = {
  isLoading: false,
  error: false,
  bookingList: []
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROMOTIONS
    getBookingsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.bookingList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const { getBookingsSuccess } = slice.actions;

// Actions

// ----------------------------------------------------------------------

export function getBookingList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/booking/all');
      dispatch(slice.actions.getBookingsSuccess(response.data.bookings));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}
