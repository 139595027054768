import { useLazyQuery } from '@apollo/client';
import { Card, Grid2, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../../../../@type/merchant';
import { GET_PRODUCTS } from '../../../../../_apis_/queries/merchant';
import LoadingComponent from '../../../../../components/LoadingComponent';
import Productitem from './Productitem';

const Products = () => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [getAllProducts] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      if (res?.getProducts) {
        setAllProducts(res?.getProducts);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={{ xs: 12, sm: 6 }}>
        <Card sx={{ p: 3 }} style={{ minHeight: 600 }}>
          <Typography sx={{ margin: '10px 0 20px' }} gutterBottom variant="h5" component="div">
            {t('Select a plan')}
          </Typography>
          <Grid2 container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
            {allProducts.map((product: Product, index: number) => {
              return <Productitem key={index} product={product} />;
            })}
          </Grid2>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default Products;
