import { AppSection } from '@/@type/appSection';
import { FLOAT } from '@/@type/global';
import { AppContentLocation, AppContentType, AppViewType } from '@/__generated__/types';
import { createStringValidation, createStrNumberValidation } from '@/utils/regexConfig';
import { array, mixed, object, SchemaOf, string } from 'yup';

export const appSectionValidationSchema: SchemaOf<Partial<AppSection>> = object({
  id: string().optional(),
  contentType: mixed<AppContentType>()
    .oneOf(Object.values(AppContentType), 'Invalid content type')
    .required(),
  location: mixed<AppContentLocation>()
    .oneOf(Object.values(AppContentLocation), 'Invalid app content location type')
    .required(),
  viewType: mixed<AppViewType>().oneOf(Object.values(AppViewType), 'Invalid view type').required(),
  sort: createStrNumberValidation({ isGreaterThanZero: false }),
  merchants: array().optional(),
  merchantIds: array().required(),
  items: array().required(),
  title: createStringValidation({}),
  description: createStringValidation({ isRequired: false }),
  image: createStringValidation({ isRequired: false }),
  status: mixed<AppContentType>()
    .oneOf(Object.values(AppContentType), 'Invalid status type')
    .optional(),
  radiumInKm: createStrNumberValidation({
    type: FLOAT,
    isGreaterThanZero: false,
    isRequired: false
  }),
  coordinate: object()
    .shape({
      latitude: mixed().test(
        'is-valid-latitude',
        'Latitude must be between -90 and 90 degrees',
        (value) => value === undefined || (parseFloat(value) >= -90 && parseFloat(value) <= 90)
      ),
      longitude: mixed()
        .test(
          'is-valid-longitude',
          'Longitude must be between -180 and 180 degrees',
          (value) => value === undefined || (parseFloat(value) >= -180 && parseFloat(value) <= 180)
        )
        .when('latitude', {
          is: (latitude: any) => latitude === undefined,
          then: mixed().test(
            'is-latitude-required',
            'Latitude is required when longitude is present',
            (longitude) => longitude === undefined
          )
        })
    })
    .optional()
});
