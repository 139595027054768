import { Grid2 } from '@mui/material';
import { PromoCustomerSegment } from '@/components/_dashboard/promotion/components/promoCustomerSegment';
import { CommonInput } from '@/components/_dashboard/promotion/formik/commonInput';
import {
  getBaseInfoValidationList,
  getPromoFuncType
} from '@/components/_dashboard/promotion/tools';
import React, { useCallback, useEffect } from 'react';
import { IPromoProps } from '../../type';
import { renderContent, tabContent } from '../tabContent';
import { getErrorMessage } from '@/utils/stringHelper';

export const OfferBaseInfo: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const { values } = formik;
  const canMove = useCallback(async () => {
    let message = '';
    let canMove = true;
    try {
      const promoFuncType = getPromoFuncType(values);
      const list = getBaseInfoValidationList(promoFuncType);
      for (const path of list) {
        if (path) {
          await setFieldTouchedNameFunc(path);
        }
      }
    } catch (e) {
      message = getErrorMessage(e);
      canMove = false;
    }
    return {
      message: message,
      canMove: canMove
    };
  }, [setFieldTouchedNameFunc, values]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      sxSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'name'}
          label="Offer Name"
          placeholder="Please enter the name of the offer"
        />
      )
    },
    {
      mdSize: 12,
      sxSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'description'}
          label="Offer Description"
          placeholder="Please enter the description of the offer"
          multiline={true}
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'numberOfDeals'}
          type="number"
          label="How many deals would you like to publish?"
          placeholder="Number of Deals"
        />
      )
    },
    {
      node: <></>
    },
    {
      node: <PromoCustomerSegment formik={formik} />
    }
  ];
  return (
    <Grid2 sx={{ width: '100%' }} container spacing={1}>
      {renderContent(commonContents)}
    </Grid2>
  );
};
