import { convertTime, parseTime } from '@/utils/helperTime';
import { darkenColor } from '@/utils/rgb';
import plusIcon from '@iconify/icons-eva/plus-outline';
import deleteIcon from '@iconify/icons-eva/trash-fill';
import { Icon } from '@iconify/react';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTime } from 'luxon';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DeleteButton } from '../styles';
import { EffectiveDateTimeType, HoursType, useEffectDateTimeList } from './type/type';
interface EffectDateSelectorProps {
  index: number;
  effectDate: EffectiveDateTimeType;
}

interface CustomDatePickerProps {
  label: string;
  isError: boolean;
  textError: string;
  value: string;
  isReadOnly: boolean;
  onChange: (newValue: DateTime | null) => void;
}

function CustomDatePicker(props: CustomDatePickerProps) {
  return (
    <DesktopDatePicker
      label={props.label}
      readOnly={props.isReadOnly}
      value={props.value ? DateTime.fromFormat(props.value, 'yyyy-MM-dd') : null}
      onChange={props.onChange}
      slotProps={{
        textField: {
          fullWidth: true,
          error: Boolean(props.isError),
          helperText: Boolean(props.isError) && props.textError
        }
      }}
    />
  );
}
export const EffectDateSelector: React.FC<EffectDateSelectorProps> = (
  props: EffectDateSelectorProps
) => {
  const { index, effectDate } = props;
  const { t } = useTranslation();
  const { updateStartDate, updateEndDate, updateHours, isOffer = false } = useEffectDateTimeList();
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const { pathname } = useLocation();
  const theme = useTheme();
  const btnBackgroundColor = isOffer ? '#DCC179' : theme.palette.primary.main;
  const isEdit = pathname.includes('edit');

  const handleChangeStartDate = (newValue: DateTime | null) => {
    if (!newValue) return;
    const date = newValue.toFormat('yyyy-MM-dd');
    updateStartDate(index, date);
  };

  const handleChangeEndDate = (newValue: DateTime | null) => {
    if (!newValue) return;
    const date = newValue.toFormat('yyyy-MM-dd');
    updateEndDate(index, date);
  };

  const handleChangeOpenTimeStart = (startTime: any, hoursIndex: number) => {
    if (!startTime) return;
    const newHours = [...effectDate.hours];
    newHours[hoursIndex].openStart = convertTime(startTime, 'time');
    updateHours(index, newHours);
  };
  const handleChangeOpenTimeEnd = (endTime: any, hoursIndex: number) => {
    if (!endTime) return;
    const newHours = [...effectDate.hours];
    newHours[hoursIndex].openEnd = convertTime(endTime, 'time');
    updateHours(index, newHours);
  };

  const handleDeleteEffectiveTime = (hoursIndex: number) => {
    // make an copy
    const newHours = [...effectDate.hours];
    // remove the item
    newHours.splice(hoursIndex, 1);
    updateHours(index, newHours);
  };

  const handleAddEffectiveTime = () => {
    const newHours = [...effectDate.hours];
    newHours.push({
      openStart: convertTime(DateTime.now().startOf('day').toJSDate(), 'time'),
      openEnd: convertTime(DateTime.now().endOf('day').toJSDate(), 'time')
    });
    updateHours(index, newHours);
  };

  const checkErrorStartDate = useCallback(() => {
    const startDate = DateTime.fromISO(effectDate.startDate);
    return startDate.diffNow('days').days < 0;
  }, [effectDate.startDate]);

  const checkErrorEndDate = useCallback(() => {
    const endDate = DateTime.fromISO(effectDate.endDate);
    const startDate = DateTime.fromISO(effectDate.startDate);

    const isEndDatePassed = endDate.diffNow('days').days < 0;
    const isEndDateBeforeStartDate = endDate.diff(startDate, 'minutes').minutes < 0;

    return isEndDatePassed || isEndDateBeforeStartDate;
  }, [effectDate.endDate, effectDate.startDate]);

  useEffect(() => {
    if (isEdit) {
      setErrorEndDate(checkErrorEndDate());
    } else {
      setErrorStartDate(checkErrorStartDate());
      setErrorEndDate(checkErrorEndDate());
    }
  }, [effectDate.startDate, effectDate.endDate, checkErrorEndDate, checkErrorStartDate, isEdit]);

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
        <CustomDatePicker
          label={t('Start Date')}
          isError={errorStartDate}
          isReadOnly={isEdit ? true : false}
          value={effectDate.startDate}
          onChange={handleChangeStartDate}
          textError={t('Invalid Date')}
        />
        <CustomDatePicker
          label={t('End Date')}
          isError={errorEndDate}
          isReadOnly={false}
          value={effectDate.endDate}
          onChange={handleChangeEndDate}
          textError={t('Invalid Date')}
        />
      </Stack>
      {effectDate.hours.length > 0 &&
        effectDate.hours.map((item: HoursType, index: number) => (
          <Fragment key={index}>
            <Stack direction={{ xs: 'column', sm: 'row' }} marginTop={1} spacing={{ xs: 3, sm: 2 }}>
              <TimePicker
                ampm={false}
                label={t('Open Time')}
                value={parseTime(item.openStart)}
                onChange={(value) => handleChangeOpenTimeStart(value, index)}
                slotProps={{
                  textField: {
                    error: true,
                    fullWidth: true
                  }
                }}
              />
              <TimePicker
                ampm={false}
                label={t('Close Time')}
                value={parseTime(item.openEnd)}
                onChange={(value) => handleChangeOpenTimeEnd(value, index)}
                slotProps={{
                  textField: {
                    fullWidth: true
                  }
                }}
              />
            </Stack>
            {effectDate.hours.length > 1 && (
              <DeleteButton onClick={() => handleDeleteEffectiveTime(index)}>
                <Icon icon={deleteIcon} width={16} height={16} />
                &nbsp;
                <span>{t('Remove')}</span>
              </DeleteButton>
            )}
          </Fragment>
        ))}
      <Box
        sx={{
          mt: 12,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          sx={{
            width: '100%',
            backgroundColor: btnBackgroundColor,
            '&:hover': {
              backgroundColor: darkenColor(btnBackgroundColor, 20)
            },
            color: '#FFFFFF'
          }}
          onClick={handleAddEffectiveTime}
        >
          <Icon icon={plusIcon} width={16} height={16} />
          &nbsp;
          <span>{t('Add effective time')}</span>
        </Button>
      </Box>
    </>
  );
};
