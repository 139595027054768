import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormikProps } from 'formik';
import { Promotion } from '@/@type/promotion';
import { useParams } from 'react-router-dom';
import React from 'react';
import { PromotionType } from '@/__generated__/types';

export enum DiscountType {
  spend = 'spend',
  buyN = 'buyN'
}
interface promoTypeProp {
  formik: FormikProps<Promotion>;
}

export const useDiscountTypeSelector = (props: promoTypeProp) => {
  const { formik } = props;
  const params = useParams();
  const isEdit = Boolean(params.name);
  const isOffer = formik.values.isOffer || false;
  const radioStyle = isOffer ? { color: '#DCC179' } : {};
  const [discountType, setDiscountType] = React.useState<DiscountType>(getInitType());

  function getInitType() {
    if (!isEdit) {
      if (formik.values.type === PromotionType.BuynGetFree) {
        return DiscountType.buyN;
      }
      return DiscountType.spend;
    }
    const buyN = formik.values.buyN;
    if (buyN && Number.isFinite(Number(buyN)) && Number(buyN) > 0) {
      return DiscountType.buyN;
    }
    return DiscountType.spend;
  }
  const handleDiscountTypeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as DiscountType;
    if (value === DiscountType.buyN) {
      formik.setFieldValue('minimumAmount', undefined);
    }
    if (value === DiscountType.spend) {
      formik.setFieldValue('buyN', undefined);
    }
    setDiscountType(event.target.value as DiscountType);
  };

  const discountTypeElement = (
    <>
      <FormControl>
        <RadioGroup row name="type" value={discountType} onChange={handleDiscountTypeOnChange}>
          <FormControlLabel
            value={DiscountType.spend}
            control={<Radio style={radioStyle} disabled={isEdit} />}
            label="Spend $"
          />
          <FormControlLabel
            value={DiscountType.buyN}
            control={<Radio style={radioStyle} disabled={isEdit} />}
            label="Buy X"
          />
        </RadioGroup>
      </FormControl>
    </>
  );

  return { discountTypeElement, discountType };
};
