import { useMutation } from '@apollo/client';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid2,
  Stack,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { MIconButton } from '@/components/@material-extend';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { CREATE_MENU_GROUP, GET_MENU_GROUPS, UPDATE_MENU_GROUP } from '@/_apis_/queries/menu';

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

interface IProps {
  edit: boolean;
  group?: any;
  index: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function NewMenuGroup({ edit, group, index, open, setOpen }: IProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const params = useParams();

  const NewMenuGroupSchema = Yup.object().shape({
    name: Yup.string().required('Menu group name is required'),
    force_max: Yup.number().required('Force max is required'),
    force_min: Yup.number().required('Force min is required')
  });

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const [createNewMenuGroup] = useMutation(CREATE_MENU_GROUP, {
    onCompleted() {
      enqueueSnackbar(t('Create menu group success'), {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    },
    refetchQueries: [{ query: GET_MENU_GROUPS, variables: { menuId: params.id } }],
    onError() {
      enqueueSnackbar(t('Create menu group fail'), {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });

  const [updateMenuGroupMutation] = useMutation(UPDATE_MENU_GROUP, {
    onCompleted() {
      enqueueSnackbar(t('Update menu group success'), {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    },
    refetchQueries: [{ query: GET_MENU_GROUPS, variables: { menuId: params.id } }],
    onError() {
      enqueueSnackbar(t('Update menu group fail'), {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      name: group?.name || '',
      force_max: group ? group?.force_max : '',
      force_min: group ? group?.force_min : '',
      allow_quanity: group ? group?.allow_quanity : true
    },
    validationSchema: NewMenuGroupSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      if (
        (values.force_max || values.force_max === 0) &&
        (values.force_min || values.force_min === 0) &&
        values.force_max >= values.force_min
      ) {
        try {
          resetForm();
          if (edit) {
            onUpdateNewMenuGroup(values);
          } else {
            onCreateNewMenuGroup(values);
          }
          setSubmitting(false);
        } catch (error: any) {
          setSubmitting(false);
          setErrors(error);
        }
      }
    }
  });

  const onUpdateNewMenuGroup = (values: any) => {
    if (params.id) {
      const variables = {
        menuId: params.id,
        groupId: group.id,
        group: {
          menuId: params.id,
          name: values.name,
          force_max: values.force_max,
          force_min: values.force_min,
          allow_quanity: values.allow_quanity,
          sort: index
        }
      };
      updateMenuGroupMutation({ variables });
      handleClose();
    }
  };

  const onCreateNewMenuGroup = (values: any) => {
    if (params.id) {
      const variables = {
        group: {
          menuId: params.id,
          name: values.name,
          force_max: values.force_max,
          force_min: values.force_min,
          allow_quanity: values.allow_quanity,
          sort: index
        }
      };
      createNewMenuGroup({ variables });
      handleClose();
    }
  };

  const onToggleActive = (allow_quanity: boolean) => {
    setFieldValue('allow_quanity', !allow_quanity);
  };

  const { errors, values, touched, handleSubmit, setFieldValue, getFieldProps, resetForm } = formik;

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{edit ? t('Update menu group') : t('Add new menu group')}</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off">
            <Grid2 container spacing={3} sx={{ marginTop: '16px' }}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Stack spacing={3} width="100%">
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={t('Menu group name')}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name && t(`${errors.name}`)}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={values.allow_quanity}
                          onClick={() => onToggleActive(values.allow_quanity)}
                        />
                      }
                      label={t('Allow quantity')}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        label={t('Force min')}
                        type="number"
                        {...getFieldProps('force_min')}
                        error={
                          Boolean(touched.force_min && errors.force_min) ||
                          Boolean(
                            (values.force_min || values.force_min === 0) &&
                              (values.force_max || values.force_max === 0) &&
                              values.force_min > values.force_max
                          )
                        }
                        helperText={
                          touched.force_min && errors.force_min && t(`${errors.force_min}`)
                        }
                      />
                      {(values.force_min || values.force_min === 0) &&
                        (values.force_max || values.force_max === 0) &&
                        values.force_min > values.force_max && (
                          <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                            {t('Force min should be less than force max')}
                          </FormHelperText>
                        )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        label={t('Force max')}
                        type="number"
                        {...getFieldProps('force_max')}
                        error={
                          Boolean(touched.force_max && errors.force_max) ||
                          Boolean(
                            (values.force_min || values.force_min === 0) &&
                              (values.force_max || values.force_max === 0) &&
                              values.force_min > values.force_max
                          )
                        }
                        helperText={
                          touched.force_max && errors.force_max && t(`${errors.force_max}`)
                        }
                      />
                      {(values.force_min || values.force_min === 0) &&
                        (values.force_max || values.force_max === 0) &&
                        values.force_min > values.force_max && (
                          <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                            {t('Force max should be greater than force min')}
                          </FormHelperText>
                        )}
                    </Box>
                  </Stack>
                </Stack>
              </Grid2>
            </Grid2>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <Button variant="outlined" onClick={() => handleSubmit()}>
          {edit ? t('Update') : t('Create New')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
