import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Promotion } from '@/@type/promotion';
import { FormikProps } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PromotionType } from '@/__generated__/types';
import { Label } from '../styles';

interface IQtyRestrictedProps {
  formik: FormikProps<Promotion>;
  label?: string;
}

export const PromoQtyRestricted = (props: IQtyRestrictedProps) => {
  const { t } = useTranslation();
  const { formik, label } = props;
  const { type } = formik.values;
  const [isSetGetItemsCountRadioChecked, setIsSetGetItemsCountRadioChecked] = useState(
    formik.values.freeN !== 'Infinite'
  );

  const handleSetGetItemsCountRadioChanged = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (type === PromotionType.Spend) {
      if (value === 'true') {
        formik.setFieldValue('discountedItemQty', 1);
        setIsSetGetItemsCountRadioChecked(true);
      } else {
        formik.setFieldValue('discountedItemQty', 'Infinite');
        setIsSetGetItemsCountRadioChecked(false);
      }
    }
    if (type === PromotionType.BuynGetFree) {
      if (value === 'true') {
        formik.setFieldValue('freeN', 1);
        setIsSetGetItemsCountRadioChecked(true);
      } else {
        formik.setFieldValue('freeN', 'Infinite');
        setIsSetGetItemsCountRadioChecked(false);
      }
    }
  };

  useEffect(() => {
    // if freeN is 'Infinite' then set radio to false
    if (formik.values.freeN === 'Infinite') {
      setIsSetGetItemsCountRadioChecked(false);
    }
  }, [formik.values.freeN]);

  return (
    <>
      {label && <Label>{t(label)}</Label>}
      <RadioGroup
        row
        value={isSetGetItemsCountRadioChecked}
        onChange={handleSetGetItemsCountRadioChanged}
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label={t('X qty')}
          checked={isSetGetItemsCountRadioChecked}
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={t('Not qty restricted')}
          checked={!isSetGetItemsCountRadioChecked}
        />
      </RadioGroup>
    </>
  );
};
