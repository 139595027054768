import { AppService, MerchantSetingsInput, UserRole } from '@/__generated__/types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid2,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { INTEGER, MAX_PROMOTIONS, STRIPE_ACCOUNT_ID } from '../../../../@type/global';
import { GET_MERCHANT, UPDATE_MERCHANT_SETTINGS } from '../../../../_apis_/queries/merchant';
import LoadingComponent from '../../../../components/LoadingComponent';
import { IOSSwitch } from '../../../../components/_dashboard/category/components/NewMenuGroup';
import { useSnackbarHelper } from '../../../../components/useSnackbarHelper';
import { RootState, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { checkAndConvert } from '../../../../utils/checkVariables';
import { handleRegexField } from '../../../../utils/regexConfig';

const MerchantSettingsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarHelper();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authUser = useSelector((state: RootState) => state.auth.user) || null;
  const [merchantSettings, setMerchantSettings] = useState<MerchantSetingsInput>({
    isStripePaymentEnabled: false,
    areSoldOutItemsDisplayedInApp: false,
    stripeAccountId: '',
    maxPromotions: 0,
    appServices: []
  });

  const [getMerchant, { data: dataMerchant }] = useLazyQuery(GET_MERCHANT, {
    onCompleted(response) {
      setMerchantSettings(response?.getMerchant?.settings);
      setIsLoading(false);
    },
    onError() {
      setIsLoading(false);
    },
    fetchPolicy: 'network-only'
  });

  const defaultStripeAccountId = dataMerchant?.getMerchant?.settings?.stripeAccountId || '';

  const [updateMerchantSettingsMutation] = useMutation(UPDATE_MERCHANT_SETTINGS, {
    onCompleted() {
      showSnackbar(t('Update Merchant Settings Success'), 'success');
      setIsSubmitting(false);
      if (typeof params?.id === 'string') {
        const hrefMerchantUpdate =
          authUser?.role === UserRole.MarketingAdmin
            ? PATH_DASHBOARD.merchant.updateMerchant
            : PATH_DASHBOARD.merchant.editById(params.id);
        navigate(hrefMerchantUpdate);
      }
    },
    refetchQueries: [{ query: GET_MERCHANT, variables: { merchantId: params.id } }],
    onError() {
      showSnackbar(t('Update Merchant Settings Fail'), 'error');
      setIsSubmitting(false);
    }
  });

  const updateMerchantSettings = (values: any) => {
    const maxPromotions = checkAndConvert(INTEGER, values.max_promotions);
    const variables = {
      merchantSettings: {
        isStripePaymentEnabled: values.is_stripe_payment_enabled,
        stripeAccountId: values.stripe_account_id,
        areSoldOutItemsDisplayedInApp: values.areSoldOutItemsDisplayedInApp,
        maxPromotions: maxPromotions,
        appServices: values.appServices
      },
      merchantId: params.id
    };
    updateMerchantSettingsMutation({ variables });
  };

  const schema = Yup.object().shape({
    stripe_account_id: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      is_stripe_payment_enabled: merchantSettings
        ? merchantSettings?.isStripePaymentEnabled
        : false,
      areSoldOutItemsDisplayedInApp: merchantSettings?.areSoldOutItemsDisplayedInApp || false,
      stripe_account_id: merchantSettings ? merchantSettings?.stripeAccountId : '',
      max_promotions: merchantSettings ? merchantSettings?.maxPromotions : '',
      appServices: merchantSettings?.appServices ? merchantSettings.appServices : []
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        if (isSubmitting) {
          return;
        }
        setIsSubmitting(true);
        updateMerchantSettings(values);
        setSubmitting(false);
      } catch (error: any) {
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const handleWithAppService = (item: AppService) => {
    const isSelected = formik.values.appServices?.includes(item);
    if (isSelected) {
      formik.setFieldValue(
        'appServices',
        formik.values.appServices.filter(
          (service: AppService | null) => service !== null && service !== item
        )
      );
    } else {
      formik.setFieldValue('appServices', [...formik.values.appServices, item]);
    }
  };

  const { values, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    setFieldValue(STRIPE_ACCOUNT_ID, defaultStripeAccountId);
  }, [formik.values.is_stripe_payment_enabled]);

  useEffect(() => {
    getMerchant({ variables: { merchantId: params.id } });
  }, [getMerchant, params]);

  if (isLoading) {
    return <LoadingComponent />;
  }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={values.is_stripe_payment_enabled || false}
                        onClick={() =>
                          setFieldValue(
                            'is_stripe_payment_enabled',
                            !values.is_stripe_payment_enabled
                          )
                        }
                      />
                    }
                    label={t('Stripe Payment Enabled')}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={values.areSoldOutItemsDisplayedInApp}
                        onClick={() =>
                          setFieldValue(
                            'areSoldOutItemsDisplayedInApp',
                            !values.areSoldOutItemsDisplayedInApp
                          )
                        }
                      />
                    }
                    label={t('display sold out label in App')}
                  />
                </Stack>
                {authUser?.role === UserRole.Admin && (
                  <TextField
                    fullWidth
                    disabled={!formik.values.is_stripe_payment_enabled}
                    label={t('Stripe Account Id')}
                    {...getFieldProps(STRIPE_ACCOUNT_ID)}
                    value={formik.values.stripe_account_id || ''}
                  />
                )}
                <TextField
                  fullWidth
                  label={t('Max promotions')}
                  onChange={(e: any) => {
                    if (e.target.value) {
                      setFieldValue(MAX_PROMOTIONS, 0);
                    }
                    handleRegexField(e, MAX_PROMOTIONS, INTEGER, setFieldValue);
                  }}
                  value={formik.values.max_promotions || ''}
                />

                <Box>
                  <Typography variant="h6" gutterBottom>
                    {'App Services:'}
                  </Typography>

                  {Object.values(AppService).map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formik.values.appServices?.includes(item)}
                          onClick={() => handleWithAppService(item)}
                        />
                      }
                      label={item}
                    />
                  ))}
                </Box>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {t('Save')}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid2>
        </Grid2>
      </Form>
    </FormikProvider>
  );
};

export default MerchantSettingsForm;
