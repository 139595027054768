import { Merchant } from '@/__generated__/types';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';

interface APPSectionFormTableRowProps {
  data: Merchant;
  merchants?: Merchant[];
  checkBoxOnChange: (merchant: Merchant, checked: boolean) => void;
}

export const APPSectionFormTableRow = (props: APPSectionFormTableRowProps) => {
  const { data, merchants, checkBoxOnChange } = props;
  const { merchantId, name, type } = data;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    checkBoxOnChange(data, checked);
  };

  useEffect(() => {
    // is data in merchants?
    if (!merchants) {
      setIsChecked(false);
      return;
    }
    const isChecked = merchants.some((merchant) => merchant.merchantId === merchantId);
    setIsChecked(isChecked);
  }, [merchants, merchantId]);

  return (
    <TableRow hover role="checkbox">
      <TableCell padding="checkbox">
        <Checkbox checked={isChecked} onChange={handleCheckBoxChange} />
      </TableCell>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{type}</TableCell>
    </TableRow>
  );
};
