export enum StepLabel {
  VoucherBaseInfo = 'Voucher Base Info',
  VoucherDetails = 'Voucher Details',
  VoucherConditions = 'Voucher Conditions',
  VoucherSummary = 'Voucher Summary',
  OfferBaseInfo = 'Offer Base Info',
  OfferDetails = 'Offer Details',
  OfferConditions = 'Offer Condition',
  VoucherCreation = 'Voucher Creation',
  OfferSummary = 'Offer Summary',
  LoyaltyBaseInfo = 'Loyalty Base Info',
  LoyaltyDetails = 'Loyalty Details',
  LoyaltyConditions = 'Loyalty Conditions',
  LoyaltySummary = 'Loyalty Summary',
  LoyaltyReward = 'Reward'
}
export interface Step {
  label: StepLabel;
  action?: () => void;
}

export const promoSteps = [
  { label: StepLabel.VoucherBaseInfo },
  { label: StepLabel.VoucherDetails },
  { label: StepLabel.VoucherConditions },
  { label: StepLabel.VoucherSummary }
];

export const loyaltySteps = [
  { label: StepLabel.LoyaltyBaseInfo },
  { label: StepLabel.LoyaltyDetails },
  { label: StepLabel.LoyaltyConditions },
  { label: StepLabel.LoyaltyReward },
  { label: StepLabel.LoyaltySummary }
];

export const offerSteps = [
  { label: StepLabel.OfferBaseInfo },
  { label: StepLabel.OfferDetails },
  { label: StepLabel.OfferConditions },
  { label: StepLabel.VoucherBaseInfo },
  { label: StepLabel.VoucherDetails },
  { label: StepLabel.VoucherConditions },
  { label: StepLabel.OfferSummary }
];

export const createSteps = (isOffer: boolean, isLoyalty: boolean) => {
  if (isLoyalty) {
    return loyaltySteps;
  }
  if (isOffer && !isLoyalty) {
    return offerSteps;
  }
  return promoSteps;
};
