import { ObApp } from '@/__generated__/types';
import { useParams } from 'react-router';
import { FAQPreviewBox } from '../../../../components/_dashboard/FAQ/components/preview/preview';

const FAQ = () => {
  const { app } = useParams();
  const validAPP = Object.values(ObApp).includes(app as ObApp)
    ? (app as ObApp)
    : ObApp.OrderbuddyApp;

  return (
    <>
      <FAQPreviewBox app={validAPP} />
    </>
  );
};
export default FAQ;
