import { ReactNode, createContext, useEffect, useState } from 'react';
import { SettingsContextProps, ThemeMode } from '../@type/settings';

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColor: 'default',
  themeStretch: false
};

const SettingsContext = createContext(initialState);

type ISettingsProviderProps = {
  children: ReactNode;
};

const SettingsProvider: React.FC<ISettingsProviderProps> = (props: ISettingsProviderProps) => {
  const { children } = props;
  const getCurrentScheme: () => 'dark' | 'light' = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

  const [themeMode, setThemeMode] = useState<ThemeMode>(getCurrentScheme);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange: (e: MediaQueryListEvent) => void = (e) => {
      setThemeMode(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);

    return (): void => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);


  const [settings, setSettings] = useState<SettingsContextProps>({
    themeMode: themeMode,
    themeDirection: initialState.themeDirection,
    themeColor: initialState.themeColor,
    themeStretch: initialState.themeStretch
  });

  useEffect(() => {
    if (settings.themeMode === themeMode) {
      return;
    }
    setSettings((prev: SettingsContextProps) => ({ ...prev, themeMode: themeMode }));
  }, [themeMode, setSettings, settings.themeMode]);

  return (
    <SettingsContext.Provider
      value={{
        ...settings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider };
