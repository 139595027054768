import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import { GiftCard, GiftCardAccount } from '../../@type/giftCard';

import { AdjustGiftCardAccountDocument, AdjustGiftCardAccountMutation, AdjustGiftCardAccountMutationVariables, CreateGiftCardAccountDocument, CreateGiftCardAccountMutation, CreateGiftCardAccountMutationVariables, CreateGiftCardsDocument, CreateGiftCardsMutation, CreateGiftCardsMutationVariables, DeleteGiftCardAccountDocument, DeleteGiftCardAccountMutation, DeleteGiftCardAccountMutationVariables, GiftCardStatus, ResentGiftCardDocument, ResentGiftCardMutation, ResentGiftCardMutationVariables, SwitchGiftCardAccountDocument, SwitchGiftCardAccountMutation, SwitchGiftCardAccountMutationVariables, UpdateGiftCardAdminUsersDocument, UpdateGiftCardAdminUsersMutation, UpdateGiftCardAdminUsersMutationVariables, VoidGiftCardDocument, VoidGiftCardMutation, VoidGiftCardMutationVariables } from '@/__generated__/types';
import { getErrorMessage } from '@/utils/stringHelper';
import { keyBy, merge } from 'lodash';
import {
  GET_ALL_GIFTCARD_ACCOUNTS_BASE_INFO,
  GET_GIFTCARD_ACCOUNT_BASE,
  GET_MY_ADJUSTMENT_RECORDS,
  GET_MY_GIFTCARDS
} from '../../_apis_/queries/giftCard';
import { createEmptyGiftCardAccount } from '../../components/_dashboard/giftCard/tools/tools';
import { client } from '../../index';
// ----------------------------------------------------------------------
type GiftCardAccountWrapper = {
  giftCardAccount: GiftCardAccount;
  isLoading: boolean;
  error: string | null;
};

type GiftCardAccountsWrapper = {
  giftCardAccounts: GiftCardAccount[];
  isLoading: boolean;
  error: string | null;
};

type GiftCardState = {
  myGiftCardAccount: GiftCardAccountWrapper;
  // admin
  gftCardAccounts: GiftCardAccountsWrapper;
};

const initialState: GiftCardState = {
  myGiftCardAccount: {
    giftCardAccount: createEmptyGiftCardAccount(),
    isLoading: false,
    error: null
  },
  gftCardAccounts: {
    giftCardAccounts: [],
    isLoading: false,
    error: null
  }
};

const slice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    setMyGiftCardAccount(state, action) {
      state.myGiftCardAccount = action.payload;
    },
    setGiftCardAccounts(state, action) {
      state.gftCardAccounts = action.payload;
    },
    voidMyGiftCard(state, action) {
      // find the giftCard in activeGiftCards and move it to voidedGiftCards
      // check activeGiftCards is empty or not

      // remove the giftCard from activeGiftCards, add it to voidedGiftCards
      // make an copy
      const newGiftCard = { ...action.payload } as GiftCard;
      newGiftCard.status = GiftCardStatus.Void;
      if (!state.myGiftCardAccount.giftCardAccount) return;
      const { activeGiftCards } = state.myGiftCardAccount.giftCardAccount;
      if (!activeGiftCards) return;
      // remove the giftCard from activeGiftCards
      state.myGiftCardAccount.giftCardAccount.activeGiftCards = activeGiftCards.filter(
        (card) => card.id !== newGiftCard.id
      );
      // check voidedGiftCards is empty? if empty, create a new array with the giftCard
      if (!state.myGiftCardAccount.giftCardAccount.voidedGiftCards) {
        state.myGiftCardAccount.giftCardAccount.voidedGiftCards = [newGiftCard];
      } else {
        state.myGiftCardAccount.giftCardAccount.voidedGiftCards.push(newGiftCard);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyGiftCardAccountBase.pending, (state) => {
        state.myGiftCardAccount.isLoading = true;
        state.myGiftCardAccount.error = null;
      })
      .addCase(fetchMyGiftCardAccountBase.fulfilled, (state, action) => {
        if (!state.myGiftCardAccount.giftCardAccount) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
        state.myGiftCardAccount.giftCardAccount = {
          ...state.myGiftCardAccount.giftCardAccount,
          ...action.payload
        };
        state.myGiftCardAccount.isLoading = false;
      })
      .addCase(fetchMyGiftCardAccountBase.rejected, (state, action) => {
        state.myGiftCardAccount.isLoading = false;
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(fetchMyGiftCards.pending, (state) => {
        state.myGiftCardAccount.error = null;
      })
      .addCase(fetchMyGiftCards.fulfilled, (state, action) => {
        // only update activeGiftCards redeemedGiftCards voidedGiftCards
        if (!state.myGiftCardAccount.giftCardAccount) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
        // overwrite activeGiftCards redeemedGiftCards voidedGiftCards
        state.myGiftCardAccount.giftCardAccount = {
          ...state.myGiftCardAccount.giftCardAccount,
          ...action.payload
        };
      })
      .addCase(fetchMyGiftCards.rejected, (state, action) => {
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(fetchMyAdjustmentRecords.pending, (state) => {
        state.myGiftCardAccount.error = null;
      })
      .addCase(fetchMyAdjustmentRecords.fulfilled, (state, action) => {
        // only update adjustmentRecords
        if (!state.myGiftCardAccount.giftCardAccount) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
        if (action.payload.adjustmentRecords) {
          state.myGiftCardAccount.giftCardAccount.adjustmentRecords =
            action.payload.adjustmentRecords;
        }
      })
      .addCase(fetchMyAdjustmentRecords.rejected, (state, action) => {
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(switchGiftCardAccountMutation.pending, (state) => {
        state.myGiftCardAccount.error = null;
      })
      .addCase(switchGiftCardAccountMutation.fulfilled, (state, action) => {
        if (action.payload) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
      })
      .addCase(switchGiftCardAccountMutation.rejected, (state, action) => {
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(fetchGiftCardAccountsBaseInfo.pending, (state) => {
        state.gftCardAccounts.isLoading = true;
        state.gftCardAccounts.error = null;
      })
      .addCase(fetchGiftCardAccountsBaseInfo.fulfilled, (state, action) => {
        state.gftCardAccounts.isLoading = false;
        // action.payload is list of giftCardAccounts.
        // for loop and overwrite giftCardAccounts by id
        // overwrite likes this {...OldData,...newData}
        state.gftCardAccounts.isLoading = false;

        const oldGiftCardAccountsById = keyBy(state.gftCardAccounts.giftCardAccounts, 'id');
        const newGiftCardAccountsById = keyBy(action.payload, 'id');

        const updatedGiftCardAccountsById = merge(
          {},
          oldGiftCardAccountsById,
          newGiftCardAccountsById
        );

        state.gftCardAccounts.giftCardAccounts = Object.values(updatedGiftCardAccountsById).filter(
          (account) => newGiftCardAccountsById[account.id]
        );
      })
      .addCase(fetchGiftCardAccountsBaseInfo.rejected, (state, action) => {
        state.gftCardAccounts.isLoading = false;
        state.gftCardAccounts.error = action.payload as string;
      });
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { voidMyGiftCard } = slice.actions;

// ----------------------------------------------------------------------

export const fetchGiftCardAccountsBaseInfo = createAsyncThunk(
  'giftCard/fetchGiftCardAccountsBaseInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_ALL_GIFTCARD_ACCOUNTS_BASE_INFO,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      return response.data.giftCardAccounts;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchMyGiftCardAccountBase = createAsyncThunk(
  'giftCard/fetchMyGiftCardAccountBase',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_GIFTCARD_ACCOUNT_BASE,
        fetchPolicy: 'network-only'
      });
      const getGiftCardDataResp = response.data.giftCardAccount;
      if (!getGiftCardDataResp) return;
      return getGiftCardDataResp;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchMyGiftCards = createAsyncThunk(
  'giftCard/fetchMyGiftCards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_MY_GIFTCARDS,
        fetchPolicy: 'network-only'
      });
      const getGiftCardDataResp = response.data.giftCardAccount;
      if (!getGiftCardDataResp) return;
      return getGiftCardDataResp;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchMyAdjustmentRecords = createAsyncThunk(
  'giftCard/fetchMyAdjustmentRecords',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_MY_ADJUSTMENT_RECORDS,
        fetchPolicy: 'network-only'
      });
      const getGiftCardDataResp = response.data.giftCardAccount;
      if (!getGiftCardDataResp) return;
      return getGiftCardDataResp;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      return rejectWithValue(errorMessage);
    }
  }
);

export const createGiftCardAccountMutation = createAsyncThunk(
  'giftCard/createGiftCardAccountMutation',
  async (input: CreateGiftCardAccountMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<CreateGiftCardAccountMutation>({
        mutation: CreateGiftCardAccountDocument,
        variables: input,
      });

      return data?.createGiftCardAccount ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
export const deleteGiftCardAccountMutation = createAsyncThunk(
  'giftCard/deleteGiftCardAccountMutation',
  async (input: DeleteGiftCardAccountMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<DeleteGiftCardAccountMutation>({
        mutation: DeleteGiftCardAccountDocument,
        variables: input,
      });

      return data?.deleteGiftCardAccount ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateGiftCardAdminUsersMutation = createAsyncThunk(
  'giftCard/updateGiftCardAdminUsersMutation',
  async (input: UpdateGiftCardAdminUsersMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<UpdateGiftCardAdminUsersMutation>({
        mutation: UpdateGiftCardAdminUsersDocument,
        variables: input,
      });

      return data?.updateGiftCardAdminUsers ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
export const adjustGiftCardAccountMutation = createAsyncThunk(
  'giftCard/adjustGiftCardAccountMutation',
  async (input: AdjustGiftCardAccountMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<AdjustGiftCardAccountMutation>({
        mutation: AdjustGiftCardAccountDocument,
        variables: input,
      });

      return data?.adjustGiftCardAccount ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createGiftCardsMutation = createAsyncThunk(
  'giftCard/createGiftCardsMutation',
  async (input: CreateGiftCardsMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<CreateGiftCardsMutation>({
        mutation: CreateGiftCardsDocument,
        variables: input,
      });

      return data?.createGiftCards ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const voidGiftCardMutation = createAsyncThunk(
  'giftCard/voidGiftCardMutation',
  async (input: VoidGiftCardMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<VoidGiftCardMutation>({
        mutation: VoidGiftCardDocument,
        variables: input,
      });

      return data?.voidGiftCard ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const resentGiftCardMutation = createAsyncThunk(
  'giftCard/resentGiftCardMutation',
  async (input: ResentGiftCardMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<ResentGiftCardMutation>({
        mutation: ResentGiftCardDocument,
        variables: input,
      });

      return data?.resentGiftCard ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const switchGiftCardAccountMutation = createAsyncThunk(
  'giftCard/switchGiftCardAccount',
  async (input: SwitchGiftCardAccountMutationVariables, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<SwitchGiftCardAccountMutation>({
        mutation: SwitchGiftCardAccountDocument,
        variables: input,
      });

      return data?.switchGiftCardAccount ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);