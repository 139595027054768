import { Promotion } from '@/@type/promotion';
import { PromotionType } from '@/__generated__/types';
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { isValidIndex } from '@/utils/checkVariables';
import { Box, Button, Grid2, Paper } from '@mui/material';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { CommonInput } from '../formik/commonInput';
import { Label } from '../styles';
import { createPromoValidator, initLoyaltyBenefit } from '../tools';
import { PromoSelector } from './PromoSelector';

interface promoLoyaltyBenefitsProps {
  formik: FormikProps<Promotion>;
}

export const PromoLoyaltyBenefits: React.FC<promoLoyaltyBenefitsProps> = (
  props: promoLoyaltyBenefitsProps
) => {
  const { formik } = props;
  const { values } = formik;
  const { loyaltySettings } = values;
  const { loyaltyBenefits } = loyaltySettings;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const handleAddBenefitOnClick = () => {
    const newBenefit = initLoyaltyBenefit();
    const newBenefits = [...loyaltyBenefits, newBenefit];
    formik.setFieldValue('loyaltySettings.loyaltyBenefits', newBenefits);
  };

  const getLoyaltyPath = (index: number, key: string) => {
    if (!isValidIndex(index, loyaltyBenefits)) {
      return '';
    }
    return `loyaltySettings.loyaltyBenefits[${index}].${key}`;
  };
  const handleRemoveBtnOnClick = (index: number) => {
    return () => {
      if (loyaltyBenefits.length === 1) {
        formik.setFieldValue('loyaltySettings.loyaltyBenefits', [initLoyaltyBenefit()]);
        return;
      }
      if (!isValidIndex(index, loyaltyBenefits)) {
        showSnackbar('Invalid index', 'error');
        return;
      }
      const newBenefits = [...loyaltyBenefits];
      newBenefits.splice(index, 1);
      formik.setFieldValue('loyaltySettings.loyaltyBenefits', newBenefits);
    };
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center'
        }}
      >
        <Button onClick={handleAddBenefitOnClick} variant="contained">
          Add
        </Button>
      </Box>
      {loyaltyBenefits.map((benefit, index) => {
        return (
          <Paper elevation={3} key={index} sx={{ padding: '16px' }}>
            <Grid2 container spacing={1}>
              <Grid2 size={{ xs: 12, sm: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <CommonInput
                    formik={formik}
                    fieldPath={`loyaltySettings.loyaltyBenefits[${index}].name`}
                    label="Name"
                    placeholder="Name?"
                  />
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <CommonInput
                    formik={formik}
                    fieldPath={`loyaltySettings.loyaltyBenefits[${index}].requiredPoints`}
                    label="Required points"
                    type="number"
                    placeholder="Need 100 points to redeem."
                  />
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Label>{t('Rewards')}</Label>
                  <PromoSelector
                    formik={formik}
                    formikPath={getLoyaltyPath(index, 'benefitPromotionIds')}
                    promoValidator={createPromoValidator([
                      PromotionType.LuckyDraw,
                      PromotionType.Loyalty
                    ])}
                  />
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 10 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <CommonInput
                    formik={formik}
                    fieldPath={`loyaltySettings.loyaltyBenefits[${index}].description`}
                    label="Description"
                    placeholder="Please enter the description of the voucher."
                    multiline={true}
                  />
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Button
                    onClick={handleRemoveBtnOnClick(index)}
                    sx={{ marginTop: '16px' }}
                    variant="contained"
                    color="error"
                  >
                    Remove
                  </Button>
                </Box>
              </Grid2>
            </Grid2>
          </Paper>
        );
      })}
    </>
  );
};
