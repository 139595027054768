import { useLocation, useParams } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import { useTranslation } from 'react-i18next';
import { CreateOfferForm } from '../../../components/_dashboard/promotion/create/createOfferForm';
import { CreatePromotionForm } from '../../../components/_dashboard/promotion/create/createPromotionForm';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';

// ----------------------------------------------------------------------

enum DisplayForm {
  promotion = 'promotion',
  offer = 'offer'
}

enum PromotionType {
  Percentage = 'Amount/Percentage',
  Item = 'Item',
  StackableSpend = 'Stackable Spend',
  LuckyDraw = 'Lucky Draw',
  LoyaltyPoints = 'Loyalty Points'
}

export const Promotion = () => {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { name = '' } = useParams();
  const isLoyalty = pathname.includes('loyalty');
  const isOffer = pathname.includes('offer');
  const isEdit = name ? true : false
  const displayForm = isOffer ? DisplayForm.offer : DisplayForm.promotion;

  const getPromotionName = (pathname: string): string => {
    if (pathname.includes('percentage')) {
      return PromotionType.Percentage
    }
    if (pathname.includes('buynGetFree')) {
      return PromotionType.Item
    }
    if (pathname.includes('spend')) {
      return PromotionType.StackableSpend;
    }
    if (pathname.includes('luckyDraw')) {
      return PromotionType.LuckyDraw;
    }
    if (isLoyalty) {
      return PromotionType.LoyaltyPoints;
    }
    return '';
  };

  const getPromotionSuffix = (isLoyalty: boolean, isOffer: boolean): string => {
    if (isOffer) {
      return 'Offer';
    }
    if (isLoyalty) {
      return 'Loyalty';
    }
    return 'Promotion';
  };

  const getTitle = () => {
    const title = isEdit ? t('Edit') : t('Create');
    const promotionType = getPromotionName(pathname);
    if (promotionType.includes('Loyalty')) {
      return `${title} ${t(promotionType)}`;
    }
    const promotionSuffix = getPromotionSuffix(isLoyalty, isOffer);
    return `${title} ${t(promotionType)} ${t(promotionSuffix)}`;
  }

  return (
    <Page title={t('Create a new promotion')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={getTitle()}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Promotion'), href: PATH_DASHBOARD.promotion.list },
            { name: getTitle() }
          ]}
        />
        {displayForm === DisplayForm.promotion && <CreatePromotionForm isLoyalty={isLoyalty} />}
        {displayForm === DisplayForm.offer && <CreateOfferForm isLoyalty={isLoyalty} />}
      </Container>
    </Page>
  );
};
