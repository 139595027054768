import { Promotion } from '@/@type/promotion';
import { FormikProps } from 'formik';
import { DeepKeyOf } from '../../formik/type';
import { canMoveProps } from '../stepper/stepper';
import { StepLabel } from '../stepper/steps';
import { OfferBaseInfo } from './offer/baseInfo';
import { OfferConditions } from './offer/conditions';
import { OfferDetails } from './offer/details';
import { VoucherBaseInfo } from './voucher/baseInfo';
import { VoucherConditions } from './voucher/conditions';
import { VoucherDetails } from './voucher/details';
import { VoucherReward } from './voucher/reward';
import { Summary } from './voucher/summary';


export interface IStepRendererProps {
    currentStep: StepLabel;
    commonComponentProps: {
        formik: FormikProps<Promotion>
        setCanMoveFunc: React.Dispatch<React.SetStateAction<() => Promise<canMoveProps>>>;
        setFieldTouchedNameFunc: (name: DeepKeyOf<Promotion>) => Promise<void>;
    };
    formik: FormikProps<Promotion>;

}
export const StepRenderer: React.FC<IStepRendererProps> = (props) => {
    const { currentStep, commonComponentProps, formik } = props;
    const stepComponentMap: Record<StepLabel, () => JSX.Element> = {
        [StepLabel.VoucherBaseInfo]: () => <VoucherBaseInfo {...commonComponentProps} />,
        [StepLabel.VoucherDetails]: () => <VoucherDetails {...commonComponentProps} />,
        [StepLabel.VoucherConditions]: () => <VoucherConditions {...commonComponentProps} />,
        [StepLabel.VoucherSummary]: () => <Summary formik={formik} />,
        [StepLabel.OfferBaseInfo]: () => <OfferBaseInfo {...commonComponentProps} />,
        [StepLabel.OfferDetails]: () => <OfferDetails {...commonComponentProps} />,
        [StepLabel.OfferConditions]: () => <OfferConditions {...commonComponentProps} />,
        [StepLabel.VoucherCreation]: () => <div>Voucher Creation Placeholder</div>,
        [StepLabel.OfferSummary]: () => <Summary formik={formik} />,
        [StepLabel.LoyaltyBaseInfo]: () => <VoucherBaseInfo {...commonComponentProps} />,
        [StepLabel.LoyaltyDetails]: () => <VoucherDetails {...commonComponentProps} />,
        [StepLabel.LoyaltyConditions]: () => <VoucherConditions {...commonComponentProps} />,
        [StepLabel.LoyaltySummary]: () => <Summary formik={formik} />,
        [StepLabel.LoyaltyReward]: () => <VoucherReward {...commonComponentProps} />,
    };

    const renderComponent = stepComponentMap[currentStep] || (() => <div>Step not found</div>);

    return renderComponent();
};
