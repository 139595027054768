import { ObApp, ObfaqSection } from '@/__generated__/types';
import { queryFAQ } from '@/redux/slices/faq';
import { dispatch, RootState, useSelector } from '@/redux/store';
import { getErrorMessage } from '@/utils/stringHelper';
import { Box, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { PreviewSectionsBox } from '../sections/previewSectionsBox';

interface IFAQPreviewBoxBoxProps {
  app: ObApp;
}
export const FAQPreviewBox = (props: IFAQPreviewBoxBoxProps) => {
  const { app } = props;
  // const { showSnackbar } = useSnackbarHelper();
  const FAQData = useSelector((state: RootState) => state.FAQ.FAQData);
  const obfaq = FAQData[app];
  const sections = (obfaq?.sections ?? []).filter(
    (section): section is ObfaqSection => section !== null
  );

  const [isLoading, setIsLoading] = useState(false);

  const fetchFAQ = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(queryFAQ({ app }));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      console.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [app, setIsLoading]);

  useEffect(() => {
    fetchFAQ();
  }, [fetchFAQ]);

  return (
    <>
      {isLoading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      )}
      {!isLoading && <PreviewSectionsBox sections={sections} />}
    </>
  );
};
