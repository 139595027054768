import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
// material
import { loginMutation } from '@/redux/slices/auth';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { dispatch } from '../../../redux/store';
// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
//
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { getErrorMessage } from '@/utils/stringHelper';
// import { useHistory } from 'react-router-dom';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

export default function LoginForm() {
  const { showSnackbar } = useSnackbarHelper();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    try {
      const loginResult = await dispatch(loginMutation({ email: email, password: password })).unwrap();
      if (loginResult) {
        if (loginResult.role !== 'admin' && loginResult.merchantId === '') {
          navigate(PATH_PAGE.page404);
          return;
        }
        navigate(PATH_DASHBOARD.root, { replace: true });
        showSnackbar('Login Success', 'success');
        return;
      }
      handleLoginError(loginResult)
      showSnackbar('Login Failed', 'error');
    } catch (error) {
      handleLoginError(getErrorMessage(error));
    }
  }

  const handleLoginError = (error: string) => {
    // "error error=Error: The password is invalid or the user does not have a password."
    if (error.includes('The password is invalid or the user does not have a password.')) {
      showSnackbar('Invalid Password', 'error');
      return;
    }
    // here is no user record corresponding to this identifier.
    if (error.includes('here is no user record corresponding to this identifier.')) {
      showSnackbar('No user record', 'error');
      return;
    }
    // error error=Error: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.
    if (
      error.includes(
        'Access to this account has been temporarily disabled due to many failed login attempts.'
      )
    ) {
      showSnackbar('Account has been temporarily disabled', 'error');
      return;
    }
    showSnackbar(error, 'error');
  };

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const email = values.email;
      const password = values.password;
      await login(email, password);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
