import { AppSection, AppSectionsDocument, AppSectionsQuery, ContentStatus, CreateAppSectionDocument, CreateAppSectionMutation, CreateAppSectionMutationVariables, DeleteAppSectionDocument, DeleteAppSectionMutation, DeleteAppSectionMutationVariables, UpdateAppSectionDocument, UpdateAppSectionMutation, UpdateAppSectionMutationVariables } from '@/__generated__/types';
import { getErrorMessage } from '@/utils/stringHelper';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { removeTypenameKey } from '../../components/_dashboard/promotion/tools';
import { client } from '../../index';

// ----------------------------------------------------------------------
type APPSectionState = {
  appSections: AppSection[];
};

const initialState: APPSectionState = {
  appSections: []
};

const slice = createSlice({
  name: 'appSection',
  initialState,
  reducers: {
    // update sort property
    updateSort(state, action: PayloadAction<{ sectionIndex: number; sort: number }>) {
      const { sectionIndex, sort } = action.payload;
      // check if the section exists
      const section = state.appSections.find((_, index) => index === sectionIndex);
      if (!section) return;
      // make a copy
      const sectionCopy = { ...section };
      // update the sort property
      sectionCopy.sort = sort;
      // update the state
      state.appSections[sectionIndex] = sectionCopy;
      // sort the sections
      state.appSections = state.appSections.sort((a: AppSection, b: AppSection) => {
        const sortA = a.sort ?? 0;
        const sortB = b.sort ?? 0;
        return sortA - sortB;
      });
    },
    updateSatus(state, action: PayloadAction<{ sectionId: string; status: ContentStatus }>) {
      const { sectionId, status } = action.payload;
      // check if the section exists
      const sectionIndex = state.appSections.findIndex((section) => section.id === sectionId);
      if (sectionIndex === -1) return;
      // make a copy
      const sectionCopy = { ...state.appSections[sectionIndex] };
      // update the status property
      sectionCopy.status = status;
      // update the state
      state.appSections[sectionIndex] = sectionCopy;
    },
    deleteAppSection(state, action: PayloadAction<{ sectionId: string }>) {
      const { sectionId } = action.payload;
      const index = state.appSections.findIndex((section) => section.id === sectionId);
      if (index === -1) return;
      // remove the section and update the state
      state.appSections.splice(index, 1);
    },
    updateAppSection(state, action: PayloadAction<{ sectionId: string; section: AppSection }>) {
      const { sectionId, section } = action.payload;
      const index = state.appSections.findIndex((section) => section.id === sectionId);
      if (index === -1) return;
      // update the section and update the state
      state.appSections[index] = section;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchAPPSections.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.appSections = payload
        .filter((section): section is AppSection => section !== null)
        .slice()
        .sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0));
    });

  }
});

// export const APP_SECTIONS = gql`
//   query appSections {
//     appSections {
export const fetchAPPSections = createAsyncThunk(
  'appSection/queryAPPSections',
  async (_, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.query<AppSectionsQuery>({
        query: AppSectionsDocument,
        fetchPolicy: 'network-only',
      });

      if (errors) return rejectWithValue(JSON.stringify(errors));
      if (!data) return rejectWithValue('No data returned');
      const copy = cloneDeep(data.appSections);
      removeTypenameKey(copy);
      return copy;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// export const UPDATE_APP_SECTION = gql`
//   mutation updateAppSection($section: AppSectionInput!, $sectionId: ID!) {
//     updateAppSection(section: $section, sectionId: $sectionId)
//   }
// `;
export const updateAppSectionMutation = createAsyncThunk(
  'appSection/updateAppSectionMutation',
  async (input: UpdateAppSectionMutationVariables, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate<UpdateAppSectionMutation>({
        mutation: UpdateAppSectionDocument,
        variables: input,
      });

      if (errors) {
        return rejectWithValue(JSON.stringify(errors));
      }
      return data?.updateAppSection ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
// export const CREATE_APP_SECTION = gql`
//   mutation createAppSection($section: AppSectionInput!) {
//     createAppSection(section: $section)
//   }
// `;
export const createAppSectionMutation = createAsyncThunk(
  'appSection/createAppSectionMutation',
  async (input: CreateAppSectionMutationVariables, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate<CreateAppSectionMutation>({
        mutation: CreateAppSectionDocument,
        variables: input,
      });

      if (errors) {
        return rejectWithValue(JSON.stringify(errors));
      }
      return data?.createAppSection ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// export const DELETE_APP_SECTION = gql`
//   mutation deleteAppSection($sectionId: ID!) {
//     deleteAppSection(sectionId: $sectionId)
//   }
// `;
export const deleteAppSectionMutation = createAsyncThunk(
  'appSection/deleteAppSectionMutation',
  async (input: DeleteAppSectionMutationVariables, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.mutate<DeleteAppSectionMutation>({
        mutation: DeleteAppSectionDocument,
        variables: input,
      });

      if (errors) {
        return rejectWithValue(JSON.stringify(errors));
      }
      return data?.deleteAppSection ?? rejectWithValue('No data returned');
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// export updateSort
export const { updateSort, updateSatus, updateAppSection, deleteAppSection } = slice.actions;

// Reducer
export default slice.reducer;
